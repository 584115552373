import React from 'react';
import './loading.scss';
import { Image } from 'botbit-ui-components';
import { envConfig } from '../../../config/config';
import useWindowDimensions from '../../utils/useWindowDimensions';
import { MDBProgress, MDBSpinner } from 'mdbreact';
import SpinnerV2 from './spinner/SpinnerV2';

const Loading = (props) => {
  const { isMedium } = useWindowDimensions();
  return props.loading ? (
    <div
      onScroll={(e) => {
        e.stopPropagation();
      }}
      className={!props.fullScreen ? "p-4 m-auto d-table" : "loading-overlay"}
    >
      <div
        className={!props.fullScreen ? "d-table m-auto" : "loading-container"}
      >
        <SpinnerV2 />
      </div>

      {props.loading.progress && (
        <div className="mx-5">
          {props.loading.progress.message && (
            <div class="alert alert-dark" role="alert" data-mdb-color="dark">
              {props.loading.progress.message}
            </div>
          )}
          <MDBProgress
            value={props.loading.progress.now}
            min={0}
            max={props.loading.progress.max}
          >
            {props.loading.progress.now}/{props.loading.progress.max}
          </MDBProgress>
        </div>
      )}
    </div>
  ) : null;
};

export default Loading;
