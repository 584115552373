import React from "react";
import { MDBSpinner } from "mdbreact";
import "./spinner.scss";

//colores posibles:
// red-spinner
// blue-spinner
// grey-spinner
// green-spinner
// yellow-spinner
// purple-spinner
// orange-spinner
// pink-spinner
// teal-spinner
// brown-spinner
// cyan-spinner
// lime-spinner
// indigo-spinner
// gold-spinner


const Spinner = ({
  
  className = "",
  color = "",
  big = false,
  small = false,
  crazy = false,
  tag = "div",
  ...rest
}) => {

   


  return (
    <MDBSpinner
      className={`ray-spinner ${color} ${className}`} 
      big={big}
      small={small}
      crazy={crazy}
      tag={tag}
      {...rest}
    />
  );
};

export default Spinner;
