import React from "react";
import "./CardList.scss";

const CardList = ({ cardsData, layout }) => {
  return (
    <div className={`card-list ${layout}`}>
      {cardsData.map((card, index) => (
        <div className="card-container" key={index}>
          {card.icon}
          <div className="title-and-description">
            <h3 className="card-title">{card.title}</h3>
            <p className="card-description">{card.description}</p>
            <hr />
          </div>
        </div>
      ))}
    </div>
  );
};

export default CardList;
