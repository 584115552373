import React from "react";
import { translate } from 'react-i18next';
import { envConfig } from '../../config/config.js';
import Button from '../../commons/components/utils/form/Button';
import { Col, Container, Row } from "react-bootstrap";
import { Field, reduxForm } from 'redux-form'
import { required } from '../../commons/components/utils/form/validations';
import Logo from './Logo';
import './login.css';
import InputField from '../../commons/components/utils/form/InputField';

class Login extends React.Component {

    render() {
        const { t, handleSubmit, submitting, handleLogin } = this.props;
        return (
          <Container className="login-container loginscreen animated fadeInDown">
            <Row>
              <Col className="col-12">
                <Logo width={"50%"} height={"100%"} />
              </Col>
              <Col className="col-12 login-column">
                <div className="ibox-content pb-1">
                  <form
                    onSubmit={handleSubmit((values) => handleLogin(values))}
                  >
                    <Field
                      name="user"
                      type="text"
                      component={InputField}
                      autoFocus
                      placeholder={t("login.username")}
                      validate={required}
                    />
                    <Field
                      name="password"
                      type="password"
                      component={InputField}
                      placeholder={t("login.password")}
                      validate={required}
                    />

                    <Button
                      block
                      disabled={submitting}
                      type="submit"
                      className="btn btn-primary block full-width m-b"
                    >
                      {t("login.login")}
                    </Button>
                    <a href={envConfig.frontendPhpUrl + "/forgotPassword.php"}>
                      <p className="text-center">
                        <small>{t("login.forgotPassword")}</small>
                      </p>
                    </a>
                  </form>
                </div>
              </Col>
            </Row>
          </Container>
        );
    }
}

export default reduxForm({ form: "login" })(translate('common')(Login))
