import React from "react";
import { UxFileUpload } from "botbit-ui-components";
import { envConfig } from "../../config/config.js";
import { useSelector } from "react-redux";
import { Field } from "formik";
import { Error } from "./Error.js";
import { uploadFile } from "../../api/companyApi.js";

const noImageUrl = envConfig.staticImgUrl + "/stores/no-image.png";

export const ImageField = ({ field, label = "", form, images, t, mapFieldValue, getPreviewSrc }) => {
  const company = useSelector((state) => state.application.selectedCompany);
  const imgSrc = getPreviewSrc(field);
  return (
    <>
      {/* <div className="row">
        <MediaGallery
          images={images}
          onSelect={(res) => {
            form.setFieldValue(
              field.name,
              mapFieldValue(res)
              //res.map((selectedImage) => ({ sourceUrl: selectedImage.image }))
            );
          }}
          // defaultSelected={[0]}
          showPreview={false}
          multipleSelection={false}
          t={t}
          erasableChilds={false}
          // triggerIcon={"plus-square"}
          labels={{
            openModalTrigger: (
              <span className="text-secondary">
                {label}
              </span>
            ),
          }}
          triggerClassName="btn btn-white"
          token={token}
          postUrl={envConfig.servicesUrl + "/upload?companyId=" + company.id}
          triggerMode="button"
        />
      </div> */}
      { 
        imgSrc ?
        <img
          className="w-75 m-2"
          src={imgSrc}
          alt=""
        /> :
        <UxFileUpload id="image" onFileSelect={(file)=> 
            uploadFile(file, company.id).then((res) => {
              form.setFieldValue(
                field.name,
                mapFieldValue(res.data.data)
              );
            })
          // file.arrayBuffer().then((res) => {
          //   uploadFile([res], company.id)
          // })
        }/>
      }
    </>
  );
};

export const FormikPostImageField = ({ label, images, errors, t }) => {
  return (
    <Field name="media">
      {({
        field, // { name, value, onChange, onBlur }
        form, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
      }) => (
        <>
          <ImageField
            field={field}
            form={form}
            images={images}
            label={label}
            mode="edit"
            t={t}
            mapFieldValue={(res) => res.map((selectedImage) => ({ sourceUrl: selectedImage }))}
            getPreviewSrc={field => field.value ? field.value[0].sourceUrl : undefined}
          />
          <Error errors={errors} isSubmitting={form.isSubmitting} />
        </>
      )}
    </Field>
  );
};


export const FormikLocalPageImageField = ({ label, images, errors, t }) => {
  return (
    <Field name="bannerPhoto">
      {({
        field, // { name, value, onChange, onBlur }
        form, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
      }) => (
        <>
          <ImageField
            field={field}
            form={form}
            images={images}
            label={label}
            mode="edit"
            t={t}
            mapFieldValue={(res) => res.map((selectedImage) => (selectedImage.image))[0]}
            getPreviewSrc={field => field.value ? field.value : noImageUrl}
          />
          <Error errors={errors} isSubmitting={form.isSubmitting} />
        </>
      )}
    </Field>
  );
};

export const FormikLocalPageLandingBannerImageField = ({ label, images, errors, t }) => {
  return (
    <Field name="bannerStyle.image">
      {({
        field, // { name, value, onChange, onBlur }
        form, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
      }) => (
        <>
          <ImageField
            field={field}
            form={form}
            images={images}
            label={label}
            mode="edit"
            t={t}
            mapFieldValue={(res) => res.map((selectedImage) => (selectedImage.image))[0]}
            getPreviewSrc={field => field.value ? field.value : noImageUrl}
          />
          <Error errors={errors} isSubmitting={form.isSubmitting} />
        </>
      )}
    </Field>
  );
};