import React from 'react'
import './SpinnerV2.scss';

const SpinnerV2 = () => {
  return (
    <div className='spinner-v2'>
      <div className='loader-container'>
        <div className='loader'></div>
      </div>
    </div>
  )
}

export default SpinnerV2