import React from "react";
import "./GiveNps.scss";
import { Image } from "botbit-ui-components";
import { envConfig } from "../../../config/config";
import { useHistory } from "react-router-dom";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { useState } from "react";
import { useEffect } from "react";
import withEventTracking from "../../../config/withEventTracking";
import { trackers } from "../../../config/withEventTracking";
import { getPublicStore } from "../../../api/storeApi";
import { createUser } from "../../../api/userApi";
import { savePrivateReview } from "../../../api/feedbackApi";
import Loading from "../../../commons/components/utils/Loading";
import translate from "../../../config/translations/translate";
import { translations } from "../../../config/translations/Feedback/GiveNps";
import { MDBBtn, MDBProgress } from "mdbreact";

const MySwal = withReactContent(Swal);

const GiveNps = ({ trackEvent, t }) => {
  const [store, setStore] = useState();
  const [userRegistered, setUserRegistered] = useState();
  const [userIdFromRegister, setUserIdFromRegister] = useState();
  const [isLoading, setIsLoading] = useState(true);

  // variables used for gating
  const [redirectUrl, setRedirectUrl] = useState(undefined);
  const [suggestionText, setSuggestionText] = useState(undefined);
  const [buttonText, setButtonText] = useState(undefined);
  // En PHP se envía eventos de showed y clicked para gating
  //const [eventType, setEventType] = useState(undefined);
  const [redirectMode, setRedirectMode] = useState(false);
  const [progress, setProgress] = useState(0);

  const history = useHistory();

  const params = new URLSearchParams(history.location.search);
  const storeId = params.get("storeId");
  const userId = params.get("userId");
  // const deliveryId = params.get("deliveryId");
  // const utmSource = params.get("utm_source");
  // const utmMedium = params.get("utm_medium");
  // const utmCampaign = params.get("utm_campaign");
  // const variant = params.get("variant");
  const name = params.get("name") ? params.get("name") : "";
  const phone = params.get("phone");
  const correlationId = params.get("correlationId");
  //const gating = params.get("g");
  const score = params.get("score");

  useEffect(() => {
    (async () => {
      const response = await getPublicStore(storeId);
      setStore(response.data.data);
      if (!userId) {
        registerUser();
      } else {
        setUserRegistered(true);
        // if (correlationId) trackEvent("REVIEW_REQUEST_SHOWED", { correlationId: correlationId }, false, [trackers.BACKEND_UNSECURE])
      }

      if (score) {
        const n = parseInt(score);
        handleNps(n, response.data.data);
      } else {
        setIsLoading(false);
      }
    })();
  }, []);

  const config = {
    TRIP_ADVISOR: {
      getUrl: (store) => {
        return store.tripAdvisorURL;
      },
      siteName: "Trip Advisor",
      eventType: "TRIP_ADVISOR_LINK_CLICK",
    },
    GOOGLE_BUSINESS: {
      getUrl: (store) => {
        return store.googleBusinessURL;
      },
      siteName: "Google",
      eventType: "GOOGLE_MY_BUSINESS_LINK_CLICK",
    },
    FACEBOOK: {
      getUrl: (store) => {
        return store.facebookURL;
      },
      siteName: "Facebook",
      eventType: "FACEBOOK_LINK_CLICK",
    },
  };

  const handleNps = (scoreSelectedByUser, store) => {
    const emailUniqueId = params.get("emailUniqueId")
      ? params.get("emailUniqueId")
      : storeId + getUserId() + Date.now();
    const storeIdParam = `storeId=${storeId}`;
    const userIdParam = `&userId=${getUserId()}`;
    const nameParam = `&name=${name}`;
    const phoneParam = `&phone=${encodeURIComponent(phone)}`;
    // const scoreIdParam = `&score=${n}`
    // const emailUniqueIdParam = `&emailUniqueId=${emailUniqueId}`
    // const deliveryIdParam = deliveryId ? `&deliveryId=${deliveryId}` : ''
    // const utmSourceParam = utmSource ? `&utm_source=${utmSource}` : ''
    // const utmMediumParam = utmMedium ? `&utm_medium=${utmMedium}` : ''
    // const utmCampaignParam = utmCampaign ? `&utm_campaign=${utmCampaign}` : ''
    // const variantParam = variant ? `&variant=${variant}` : ''
    const correlationIdParam = correlationId
      ? `&correlationId=${correlationId}`
      : "";

    // if (gating === "true") {
    //     window.location.href = `${envConfig.frontendPhpUrl}/npsFeedback.php?${storeIdParam}${userIdParam}${emailUniqueIdParam}${scoreIdParam}${deliveryIdParam}${utmSourceParam}${utmMediumParam}${utmCampaignParam}${variantParam}${correlationIdParam}`
    // } else {
    const npsBody = {
      storeId: params.get("storeId"),
      userId: params.get("userId"),
      deliveryId: params.get("deliveryId"),
      utmSource: params.get("utm_source"),
      utmMedium: params.get("utm_medium"),
      utmCampaign: params.get("utm_campaign"),
      variant: params.get("variant"),
      name: params.get("name"),
      phone: params.get("phone"),
      correlationId: params.get("correlationId"),
      g: params.get("g"),
      emailUniqueId: emailUniqueId,
      score: scoreSelectedByUser,
    };
    savePrivateReview(npsBody).then(() => {
      let mustRedirect =
        store.hasRedirectEnabled &&
        scoreSelectedByUser >= store.redirectWhenNPSGreatherThan &&
        store.redirectWhenPositiveNPSTo;
      if (store.gating && mustRedirect) {
        let siteConfig = config[store.redirectWhenPositiveNPSTo];
        if (siteConfig) {
          let suggestionText =
            t("redirecting.suggestion") + siteConfig.siteName;
          let buttonText = t("redirecting.button") + siteConfig.siteName;
          //let eventType = siteConfig.eventType;
          let redirectTo = siteConfig.getUrl(store);

          setRedirectMode(true);
          setRedirectUrl(redirectTo);
          setSuggestionText(suggestionText);
          setButtonText(buttonText);
          //setEventType(eventType)
          setIsLoading(false);

          let wait = 5000;
          let width = 0;
          let parts = 10;
          var progressBarInterval = setInterval(function () {
            width += 100 / parts;
            if (width > 100) width = 100;
            setProgress(width);
            if (width >= 100) {
              clearInterval(progressBarInterval);
            }
          }, wait / parts);

          setTimeout(function () {
            goToSite(redirectTo);
          }, wait + 1500);
        } else {
          console.error(
            "Invalid redirection site: " + store.redirectWhenPositiveNPSTo
          );
        }
      } else {
        const scoreParam = `&score=${scoreSelectedByUser}`;
        //window.location.href = `${envConfig.backofficeUrl}/left-comment?${storeIdParam}${userIdParam}${phoneParam}${nameParam}${correlationIdParam}`
        history.push(
          `/left-comment?${storeIdParam}${userIdParam}${phoneParam}${nameParam}${correlationIdParam}${scoreParam}`
        );
      }
    });
  };

  const goToSite = (url) => {
    window.location.href = url;
  };

  const npsButton = (number) => {
    return (
      <button
        key={"key-nps-button-" + number}
        onClick={() => handleNps(number, store)}
      >
        {number}
      </button>
    );
  };

  const npsStar = (number) => {
    return (
      <a
        key={"key-nps-star-" + number}
        href="#give-nps"
        className="star"
        onClick={() => handleNps(number, store)}
      >
        {" "}
      </a>
    );
  };

  const registerUser = () => {
    const userObj = {
      name: name,
      phone: phone,
    };
    const externalParams = {
      registeredThrough: "REVIEW_REQUEST",
      source: "BOTBIT",
    };
    const config = {
      headers: {
        Authorization: `Bearer ${envConfig.token}`,
      },
    };
    createUser(userObj, storeId, externalParams, config).then((res) => {
      if (res.status === 200) {
        setUserRegistered(true);
        const userId = res.data.data.id;
        setUserIdFromRegister(userId);
        /*
            if (correlationId) {
                trackEvent(
                    "REVIEW_REQUEST_SHOWED",
                    { correlationId: correlationId },
                    false,
                    [trackers.BACKEND_UNSECURE]
                );
            }
            */
      }
    });
  };

  const getUserId = () => {
    if (userId) return userId;
    else if (userIdFromRegister) return userIdFromRegister;
  };

  const storeLogo = store ? store.logo : "";
  const storeLogoFormatted = storeLogo
    ? storeLogo.replace("(", "\\(").replace(")", "\\)")
    : "";

  const getQuestion = () => {
    return store.customNpsQuestion ? (
      store.customNpsQuestion
        .replace("{tienda}", store.name)
        .replace("{nombre}", name)
        .replace("{Nombre}", name)
    ) : (
      <>
        {t("howProbably.first")} <strong>{store.name}</strong>{" "}
        {t("howProbably.second")}
      </>
    );
  };
  if (!storeId || (!userId && !phone)) {
    MySwal.fire({
      type: "error",
      title: "Oops...",
      text: "Lo sentimos, no pudimos acceder",
    });
    return {};
  } else {
    return (
      <div id="give-nps">
        {store && userRegistered && !isLoading && !redirectMode && (
          <>
            <div className="nps-header">
              <Image
                scaleFixedSize={{ width: "170px", height: "170px" }}
                src={storeLogoFormatted}
              />
            </div>
            <div className="nps-body">
              <h2>{getQuestion()}</h2>
            </div>
            <div className="give-nps-buttons">
              <div className="text-center w-100">
                {(!store.npsScoreScale || "NUMBERS" === store.npsScoreScale) &&
                  [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((n) => npsButton(n))}
                {"STARS" === store.npsScoreScale && (
                  <span className="container">
                    {[2, 4, 6, 8, 10].map((n) => npsStar(n))}
                  </span>
                )}
              </div>
              {!store.customNpsQuestion && (
                <div className="disclaimers-container">
                  <span className="left-disclaimer">0 - {t("unlikely")}</span>
                  <span className="right-disclaimer">
                    10 - {t("veryLikely")}
                  </span>
                </div>
              )}
            </div>
            <div className="powered-by">
              <span>Powered by</span>{" "}
              <img
                alt="botbit-log"
                src={`${envConfig.staticImgUrl}RAY-Brand-Red-60.png`}
              ></img>
            </div>
          </>
        )}

        {store && userRegistered && !isLoading && redirectMode && (
          <>
            <div className="nps-header">
              <Image
                scaleFixedSize={{ width: "170px", height: "170px" }}
                src={storeLogoFormatted}
              />
            </div>
            <div className="nps-body">
              <h2>{suggestionText}</h2>
            </div>
            <div className="give-nps-gating">
              <div className="gating-progress-bar">
                <MDBProgress material value={progress} height="40px" animated />
              </div>
              <MDBBtn onClick={() => goToSite(redirectUrl)}>
                {buttonText}
              </MDBBtn>
            </div>
          </>
        )}

        {isLoading && <Loading loading={true} />}
      </div>
    );
  }
};

GiveNps.translations = { ...translations };

export default withEventTracking(translate(GiveNps));
