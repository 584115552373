import { MDBBtn } from "mdbreact";
import React, { useEffect, useState } from "react";
import translate from "../../config/translations/translate";

const cookiesDisclaimerAceptedStorageKey = 'cookiesDisclaimerAcepted';


const CookiesDisclaimer = ({t})=>{
    const cookiesAcepted = localStorage.getItem(cookiesDisclaimerAceptedStorageKey);
    const [hide, setHide] = useState(cookiesAcepted);
    
    useEffect(()=>{
        if(hide)
        localStorage.setItem(cookiesDisclaimerAceptedStorageKey, "true")
    }, [hide])
    
    if (hide)
        return null;

    const contentClass = (window.innerWidth < 992) ? ' w-100 ' : ' w-50 '

    return <div className='cookies-disclaimer fixed-bottom p-3 d-flex justify-content-center '><span></span>
        <div className={`bg-light float-right p-3 text-dark flex-column ${contentClass}`}>
            {t('disclaimer')}<a target='_blank' rel="noopener noreferrer"  href="https://www.rayapp.io/politica-de-privacidad-y-cookies-utilizados-en-la-plataforma/">{t('policy')}</a><br/>
            <MDBBtn color="primary" className='w-100 m-0' onClick={()=>{setHide(true)}}>{t('accept')}</MDBBtn>
        </div>
    </div>
}

CookiesDisclaimer.translations = {
    disclaimer:{
        es:"Usamos cookies propias y de terceros para mejorar nuestros servicios y acercarte propuestas relacionada con tus preferencias mediante el análisis de tus hábitos de navegación. Si continúas navegando en esta página, consideramos que aceptas su uso en los términos indicados en la política de cookies. Siempre que lo desees puedes cambiar la configuración, para conocer más detalles revisa nuestra ",
        en:"We use our own and third-party cookies to improve our services and bring you proposals related to your preferences by analyzing your browsing habits. If you continue browsing this page, we consider that you accept its use in the terms indicated in the cookie policy. Whenever you want you can change the settings, for more details check our "
    },
    accept:{
        es:"Aceptar",
        en:"Accept"
    },
    policy:{
        es:"Política de privacidad",
        en:"Privacy policy"
    }
}

export default translate(CookiesDisclaimer);
