import React, { useEffect, useState } from "react";
import translate from "../../../../config/translations/translate";
import {
  UxMetricOutstandingComponent,
  UxActionableEditorCard,
  UxCustomTextInput,
} from "botbit-ui-components";
import ModalCategories from "./Categories/ModalCategories";
import { translations } from "../../../../config/translations/GoogleBusinessProfile/GoogleBusinessProfile";
import {
  getGoogleBusinessProfile,
  updateGoogleBusinessProfile,
  postPlaceImprovementsSuggestions,
  postMediaGoogleBusinessProfile,
} from "../../../../api/googleBusinessProfileApi";
import "./styles.scss";
import CardList from "./CardList/CardList";
import ScoreScreen from "../ScoreDescription/ScoreScreen";
import { useLocation, useParams } from "react-router-dom/cjs/react-router-dom";
import PostModalContainer from "./PostModal/PostModalContainer";
import { openPostModal } from "./PostModal/PostModalContainer";
import PhotosModalContainer, {
  openPhotosModal,
} from "./PhotosModal/PhotosModalContainer";
import ModalFinish from "./ModalFinish/ModalFinish";
//Modal when redirect
import { useHistory } from "react-router-dom";
import Icons from "./Icons/Icons";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import Spinner from "../../../../commons/components/utils/spinner/Spinner";
import withEventTracking, { trackers } from "../../../../config/withEventTracking";

const MySwal = withReactContent(Swal);

const cardsData = (t) => [
  {
    title: t("analysis.title"),
    description: t("analysis.description"),
    icon: (
      <svg
        width="40"
        height="40"
        viewBox="0 0 20 22"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M5.11428 13.7329C4.16447 12.3439 3.60344 10.6292 3.60344 8.7738C3.60344 4.14248 7.09894 0.388062 11.4109 0.388062C15.7228 0.388062 19.2183 4.14248 19.2183 8.7738C19.2183 13.4051 15.7228 17.1595 11.4109 17.1595C9.68291 17.1595 8.08608 16.5566 6.79276 15.536L6.7938 15.5368C6.75838 15.5884 6.71892 15.6378 6.67544 15.6845L2.05049 20.652C1.58142 21.1558 0.820896 21.1558 0.351822 20.652C-0.117252 20.1482 -0.117252 19.3313 0.351822 18.8275L4.97677 13.86C5.02026 13.8133 5.06625 13.7709 5.11428 13.7329ZM4.80458 8.7738C4.80458 12.6926 7.76231 15.8694 11.4109 15.8694C15.0594 15.8694 18.0171 12.6926 18.0171 8.7738C18.0171 4.85499 15.0594 1.67817 11.4109 1.67817C7.76231 1.67817 4.80458 4.85499 4.80458 8.7738Z"
          fill="#4285F4"
        />
      </svg>
    ),
  },
  {
    title: t("scoring.title"),
    description: t("scoring.description"),
    icon: (
      <svg
        width="40"
        height="40"
        viewBox="0 0 40 40"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M10.002 27.5H5.00195V35H10.002V27.5ZM22.502 17.5H17.502V35H22.502V17.5ZM35.002 5V35H30.002V5L35.002 5ZM30.002 2.5C28.6212 2.5 27.502 3.61929 27.502 5V35C27.502 36.3807 28.6212 37.5 30.002 37.5H35.002C36.3827 37.5 37.502 36.3807 37.502 35V5C37.502 3.61929 36.3827 2.5 35.002 2.5H30.002ZM15.002 17.5C15.002 16.1193 16.1212 15 17.502 15H22.502C23.8827 15 25.002 16.1193 25.002 17.5V35C25.002 36.3807 23.8827 37.5 22.502 37.5H17.502C16.1212 37.5 15.002 36.3807 15.002 35V17.5ZM2.50195 27.5C2.50195 26.1193 3.62124 25 5.00195 25H10.002C11.3827 25 12.502 26.1193 12.502 27.5V35C12.502 36.3807 11.3827 37.5 10.002 37.5H5.00195C3.62124 37.5 2.50195 36.3807 2.50195 35V27.5Z"
          fill="#4285F4"
        />
      </svg>
    ),
  },
  {
    title: t("classification.title"),
    description: t("classification.description"),
    icon: (
      <svg
        width="40"
        height="36"
        viewBox="0 0 40 36"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M19.8461 3.15252C19.8895 3.1284 19.9437 3.11328 20.0039 3.11328C20.064 3.11328 20.1183 3.1284 20.1617 3.15252C20.1992 3.17336 20.2487 3.21143 20.2985 3.29609L37.4409 32.4628C37.53 32.6144 37.5282 32.7732 37.4462 32.9211C37.4051 32.9953 37.3536 33.0442 37.3094 33.0716C37.2726 33.0944 37.2251 33.1133 37.1462 33.1133H2.86148C2.78258 33.1133 2.73511 33.0944 2.6983 33.0716C2.65407 33.0442 2.60264 32.9953 2.56152 32.9211C2.47951 32.7732 2.47771 32.6144 2.56687 32.4628L19.7093 3.29609C19.759 3.21143 19.8086 3.17336 19.8461 3.15252ZM22.4538 2.02934C21.3441 0.141263 18.6636 0.141263 17.5539 2.02934L0.411564 31.196C-0.731017 33.14 0.640697 35.6133 2.86148 35.6133H37.1462C39.367 35.6133 40.7387 33.14 39.5962 31.196L22.4538 2.02934Z"
          fill="#4285F4"
        />
        <path
          d="M17.5039 28.1133C17.5039 26.7326 18.6231 25.6133 20.0039 25.6133C21.3846 25.6133 22.5039 26.7326 22.5039 28.1133C22.5039 29.494 21.3846 30.6133 20.0039 30.6133C18.6231 30.6133 17.5039 29.494 17.5039 28.1133Z"
          fill="#4285F4"
        />
        <path
          d="M17.7488 13.1009C17.6156 11.769 18.6615 10.6133 20 10.6133C21.3385 10.6133 22.3844 11.769 22.2512 13.1009L21.3744 21.8695C21.3038 22.5756 20.7096 23.1133 20 23.1133C19.2904 23.1133 18.6962 22.5756 18.6256 21.8695L17.7488 13.1009Z"
          fill="#4285F4"
        />
      </svg>
    ),
  },
];

const CategoryContent = ({ value, buttonText, onButtonClick, t, disabled }) => {
  return (
    <div className="card-categories">
      <div className="title-categories">
        <h4>{t("currentCategories")}</h4>
      </div>
      <div className="flex-row">
        <div>
          <h5>{t("mainCategory")}</h5>
          <p className="font-bold">{value.primaryCategory}</p>
        </div>
        <div>
          <h5>{t("secondaryCategories")}</h5>
          <p>
            {value.secondaryCategories && value.secondaryCategories.join(" | ")}
          </p>
        </div>
      </div>
      <button
        className="card-button-categories"
        onClick={onButtonClick}
        disabled={disabled}
        style={disabled ? { opacity: 0.4, cursor: "not-allowed" } : {}}
      >
        {buttonText}
      </button>
    </div>
  );
};

const RenderActionableCards = ({
  summary,
  actionableConfigs,
  setSummary,
  setTotalPoints,
  setBonusPoints,
  socialLocationId,
  totalPoints,
  bonusPoints,
  t,
  setFinishIsOpen,
  isCategoriesModalOpen, 
  setCategoriesModalOpen,
  trackEvent
}) => {
  const [editableFields, setEditableFields] = useState({
    website: false,
    description: false,
  });

  const [editedValues, setEditedValues] = useState({
    website: (summary && summary.website) || "",
    description: (summary && summary.description) || "",
  });

  const [originalValues, setOriginalValues] = useState({
    website: (summary && summary.website) || "",
    description: (summary && summary.description) || "",
  });

  const renderCardContent = ({
    inputType,
    placeholder,
    buttonText,
    value,
    descriptionMinLength,
    readOnly,
    onChange,
    onButtonClick,
    key,
    onApply,
    onCancel,
    isEditing,
    disabled,
    isSpecialButton,
    isValidUrl,
  }) => {
    const contentMap = {
      text: (
        <div className="input-container">
          {isSpecialButton ? (
            <input
              className="input-example-highlighted"
              type="text"
              placeholder={placeholder}
              value={value || ""}
              readOnly={readOnly}
              onChange={onChange}
              disabled={disabled}
              style={
                disabled
                  ? {
                      opacity: 0.7,
                      cursor: "not-allowed",
                      backgroundColor: "#f5f5f5",
                    }
                  : {}
              }
            />
          ) : (
            <UxCustomTextInput
              className=""
              type="url"
              placeholder={placeholder}
              value={value || ""}
              readOnly={readOnly}
              onChange={onChange}
              disabled={disabled}
              prefix="https://"
              prefixesToRemove={['http://']}
              style={
                disabled
                  ? {
                      opacity: 0.7,
                      cursor: "not-allowed",
                      backgroundColor: "white",
                      border: "1px solid #bfbfbf",
                      borderLeft: "none",
                      borderRadius: "0 0 0 0",
                      color: "#676a6c",
                      fontSize: "13px",
                      fontFamily: "Open Sans"
                    }
                  : {
                      border: "1px solid #d9d9d9",
                      borderLeft: "none",
                      borderRadius: "0 0 0 0",
                      backgroundColor: "white",
                      color: "#676a6c",
                      fontSize: "13px",
                      fontFamily: "Open Sans"
                    }
              }
            />
          )}
          
          {isSpecialButton ? (
            <button
              className="upload-button2"
              onClick={onButtonClick}
              disabled={disabled}
              style={
                disabled
                  ? { opacity: 0.7, cursor: "not-allowed", color: "gray" }
                  : {}
              }
            >
              {buttonText}
            </button>
          ) : (
            <>
              {!isEditing ? (
                <button
                  className="edit-button"
                  onClick={onButtonClick}
                  disabled={disabled}
                  style={
                    disabled
                      ? { opacity: 0.7, cursor: "not-allowed", color: "gray" }
                      : {}
                  }
                >
                  {buttonText}
                </button>
              ) : (
                <div className="action-buttons">
                  {key === 'website' && isEditing && !isValidUrl(value) && (
                    <span className="error-message">{t("actionableConfigs.website.invalidUrl")}</span>
                  )}
                 
                  <button className="cancel-button" onClick={onCancel}>
                    {t("buttons.cancel")}
                  </button>
                  
                  <button 
                    className="apply-button" 
                    onClick={onApply} 
                    disabled={disabled || (key === 'website' && !isValidUrl(value))}
                    style={
                      disabled || (key === 'website' && !isValidUrl(value))
                        ? { 
                            opacity: 0.7, 
                            cursor: "default !important",
                            color: "gray",
                            pointerEvents: "none"
                          }
                        : {}
                    }
                  >
                    {t("buttons.apply")}
                  </button>
                </div>
              )}
            </>
          )}
        </div>
      ),
      paragraph: (
        <div className="input-container">
          <textarea
            className="textarea-example-no-highlighted"
            placeholder={placeholder}
            rows="3"
            value={value || ""}
            readOnly={readOnly}
            onChange={onChange}
            disabled={disabled}
            minLength={150}
            style={
              disabled
                ? {
                    opacity: 0.7,
                    cursor: "not-allowed",
                    backgroundColor: "#f5f5f5",
                  }
                : {}
            }
          />
          {isSpecialButton ? (
            <button
              className="no-highlighted-button-textarea"
              onClick={onButtonClick}
            >
              {buttonText}
            </button>
          ) : (
            <>
              {!isEditing ? (
                <button
                  className="no-highlighted-button-textarea"
                  onClick={onButtonClick}
                  disabled={disabled}
                  style={
                    disabled
                      ? { opacity: 0.7, cursor: "not-allowed", color: "gray" }
                      : {}
                  }
                >
                  {buttonText}
                </button>
              ) : (
                <div className="no-highlighted-button-textarea">
                  <button
                    className="cancel-button"
                    onClick={onCancel}
                    disabled={disabled}
                    style={
                      disabled
                        ? { opacity: 0.7, cursor: "not-allowed", color: "gray" }
                        : {}
                    }
                  >
                    {t("buttons.cancel")}
                  </button>
                  
                  <button
                    className="apply-button"
                    onClick={onApply}
                    disabled={disabled || (value || "").length < descriptionMinLength}
                    style={
                      disabled || (value || "").length < descriptionMinLength
                        ? { 
                            opacity: 0.7, 
                            cursor: "default !important",
                            color: "gray",
                            pointerEvents: "none"
                          }
                        : {}
                    }
                  >
                    {t("buttons.apply")}
                  </button>
                  <span className={`character-counter ${(value || "").length < descriptionMinLength ? 'error' : 'success'}`}>
                    {(value || "").length}/{descriptionMinLength}
                  </span>
                </div>
              )}
            </>
          )}
        </div>
      ),
      display: (
        <p className="input-example-highlighted">{value || placeholder}</p>
      ),
      logo: (
        <div className="logo-container-gbp">
          {(summary.profilePhotoWasEdited) ? (
           
              <Spinner color="blue-spinner"/>
          
          ) : (summary.profilePhotoValueIsOk && summary.profilePhoto) ? (
            <div className="logo-preview-container">
              <img 
                src={summary.profilePhoto} 
                alt={t("alt.companyLogo")}
                className="company-logo"
              />
            </div>
          ) : (
            <div className="logo-content-gbp">
              <div>{t("modalPhotos.noLogo")}</div>
              <div className="logo-recommendation-text">
                <span className="recommendation-label">{t("modalPhotos.recommendations.label")}</span>
                <span className="recommendation-content">
                  {t("modalPhotos.recommendations.logo")}
                </span>
              </div>
            </div>
          )}
          <button
            className="upload-button2"
            onClick={onButtonClick}
            disabled={disabled}
            style={{
              ...disabled ? { opacity: 0.7, cursor: "not-allowed", color: "gray" } : {},
              marginTop: '70px'
            }}
          >
            {buttonText}
          </button>
        </div>
      ),
      cover: (
        <div className="logo-container-gbp">
          {(summary.coverPhotoWasEdited) ? (
          
              <Spinner color="blue-spinner" />
            
          ) : (summary.coverPhotoValueIsOk && summary.coverPhoto) ? (
            <div className="logo-preview-container">
              <img 
                src={summary.coverPhoto} 
                alt={t("alt.companyCover")}
                className="company-logo"
              />
            </div>
          ) : (
            <div className="logo-content-gbp">
              <div>{t("modalPhotos.noCover")}</div>
              <div className="logo-recommendation-text">
                <span className="recommendation-label">{t("modalPhotos.recommendations.label")}</span>
                <span className="recommendation-content">
                  {t("modalPhotos.recommendations.cover")}
                </span>
              </div>
            </div>
          )}
          <button
            className="upload-button2"
            onClick={onButtonClick}
            disabled={disabled}
            style={{
              ...disabled ? { opacity: 0.7, cursor: "not-allowed", color: "gray" } : {},
              marginTop: '70px'
            }}
          >
            {buttonText}
          </button>
        </div>
      ),
      categories: (
        <>
          <ModalCategories
            isOpen={isCategoriesModalOpen}
            socialLocationId={socialLocationId}
            toggle={() => setCategoriesModalOpen(!isCategoriesModalOpen)}
            title={t("categories.modal.title")}
            subtitle={t("categories.modal.subtitle")}
            t={t}
            onApply={onApply}
            placeData={summary}
            disabled={disabled}
            trackEvent={trackEvent}
          />
          <CategoryContent
            value={value}
            buttonText={buttonText}
            onButtonClick={onButtonClick}
            disabled={disabled}
            t={t}
          />
        </>
      ),
    };
    return contentMap[inputType] || null;
  };

  const handleInputChange = (key, value) => {

      setEditedValues((prev) => ({
        ...prev,
        [key]: value,
      }));
  };
  //TODO: Proximamente hacer refactor a esto para que handleApply reciba un objeto generico con los datos a actualizar segun el caso.
  const handleApply = async (key, photoURL, currentPhotoType, categoriesArray) => {
    const currentCardConfig = actionableConfigs.find((config) => config.key === key);
    const bonusAmount = (currentCardConfig && currentCardConfig.bonusAmount()) || 0;

    const errorHandler = () => ({message: t("error")});

    try {
      const updateData = {
        [key]: editedValues[key],
      };
      let response;

      
      //TODO: Estos ifs deberian ser genericos, me imagino un config.saveChanges() que llama a backend. Pero hay que ponerle cabeza porque handleApply recibe photoURL.
      if (key === "categories") {
        response = await postPlaceImprovementsSuggestions(socialLocationId, categoriesArray, errorHandler);
      }
      else if(key === "photoUrl"){

        response = await postMediaGoogleBusinessProfile(summary.id, {
          mediaItemType: currentPhotoType,
          mediaUrl: photoURL 
        }, errorHandler);

      }
      else if(key === "logo"){
        response = await postMediaGoogleBusinessProfile(summary.id, {
          mediaItemType: currentPhotoType,
          mediaUrl: photoURL 
        }, errorHandler);
         
      }
      else if(key === "cover"){
        response = await postMediaGoogleBusinessProfile(summary.id, {
          mediaItemType: currentPhotoType,
          mediaUrl: photoURL 
        }, errorHandler);
    
      }
      else if(key === "postHistoryCount") {
        response = await getGoogleBusinessProfile(summary.id);
      
      }
      else {
        response = await updateGoogleBusinessProfile(summary.id, updateData, errorHandler);
        
      }
      

      if (response.data.result === "success") {
        setEditableFields((prev) => ({
          ...prev,
          [key]: false,
        }));

        setOriginalValues((prev) => ({
          ...prev,
          [key]: editedValues[key],
        }));
        let newTotalPoints = 0;
        
        setSummary((prevSummary) => {
          newTotalPoints = response.data.data.score - prevSummary.score;
          
          return response.data.data;
          // [key]: editedValues[key],
          // [`${key}ValueIsOk`]: true,
          // [`${key}WasEdited`]: true,
        });
        if(newTotalPoints > 0){
            setBonusPoints(newTotalPoints);
            //cuando aplicamos cambio se muestra el modal finish
            setFinishIsOpen(true);
        }
        //Evento de Mixpanel GBP_audit_edit
        trackEvent("GBP_audit_edit", { field: key, pointsAwarded: newTotalPoints }, [trackers.MIXPANEL]);
        
      } else {
        MySwal.fire({
          type: "error",
          text: t("error"),
          confirmButtonText: t("ok"),
          showCloseButton: true,
        });
      }
    } catch (error) {
      setEditedValues((prev) => ({
        ...prev,
        [key]: originalValues[key],
      }));
    }
  };

  const handleCancel = (key) => {
    const fieldKey = key.replace("ValueIsOk", "");
    setEditedValues((prev) => ({
      ...prev,
      [fieldKey]: originalValues[fieldKey],
    }));
    setEditableFields((prev) => ({
      ...prev,
      [fieldKey]: false,
    }));
  };

  //prioridad segun stateKey.
  const priority = { error: 0, moderation: 1, success: 2, displayOnly: 3 };
  
  //ordenar por prioridad
  let prioritizedActionableConfigs = actionableConfigs.sort((a,b) => priority[a.getState()] - priority[b.getState()]);
  
  //dibujar el componente
  return prioritizedActionableConfigs.map((config) => {
    const stateConfig = config.states[config.getState()];
    if (!stateConfig) return null;

    const { status, message, placeholder, buttonText } = stateConfig;

    const isFieldEditable = editableFields[config.key];

    const value = config.getValue(editedValues, config.stateKey);

      
      return (
        <UxActionableEditorCard
        key={config.key}
        title={config.title}
        bonusAmount={config.bonusAmount()}
        highlighted={config.highlighted}
        tooltip={config.tooltip}
        tooltipTitle={config.tooltipTitle}
        tooltipContent={config.tooltipContent}
        iconHeader={config.iconHeader}
        status={status}
          icon={{
            iconSuccess: Icons.iconSuccess(),
            iconError: Icons.iconError(),
            iconModeration: Icons.iconModeration(),
            iconHeader: Icons.iconHeader(),
            iconFinish: Icons.iconFinish(),
          }}
          label={{
            statusMessage: message || "",
          }}
          showMessage={true}
        >
          <div className="card-example-highlighted">
            {renderCardContent({
              key: config.key,
              inputType: config.inputType,
              placeholder,
              buttonText,
              value,
              descriptionMinLength: config.minLength,
              isValidUrl: config.urlValidity,
              isSpecialButton: config.isSpecialButton,
              readOnly: !isFieldEditable,
              onChange: (e) => handleInputChange(config.key, e.target.value),
              onButtonClick: () => config.onButtonClick(setEditableFields, handleApply),
              onApply: (categoriesArray) => handleApply(config.key,"","", categoriesArray),
              onCancel: () => handleCancel(config.key),
              isEditing: isFieldEditable,
              disabled: config.states[config.getState()].disabled,
            })}
          </div>
        </UxActionableEditorCard>
      );
    }
  )
};

const metricsOutstandingConfiguration = (summary, t, openModal) => ({
  outstanding: {
    icon: "",
    content: "React.Component",
    value: summary.score || 0,
    title: t(""),
    showBar: true,
  },
  scoreInfoLink: {
    text: t("score.howItWorks"),
    onclickHandler: openModal,
  },
});

const translateConfig = (config, t) => ({
  ...config,
  title: t(config.titleKey),
  normalText: config.normalText ? t(config.normalText) : config.normalText,
  normalText2: config.normalText2 ? t(config.normalText2) : config.normalText2,
  boldText: config.boldText ? t(config.boldText) : config.boldText,
  highlightedText: config.highlightedText
    ? t(config.highlightedText)
    : config.highlightedText,
  states: Object.entries(config.states).reduce(
    (acc, [key, state]) => ({
      ...acc,
      [key]: {
        ...state,
        message: state.messageKey ? t(state.messageKey) : state.message,
        buttonText: state.buttonTextKey
          ? t(state.buttonTextKey)
          : state.buttonText,
        placeholder: state.placeholderKey
          ? t(state.placeholderKey)
          : state.placeholder,
        placeholderKey: state.placeholderKey,
      },
    }),
    {}
  ),
});

const BusinessProfilesDetails = ({ t, trackEvent }) => {
  //Modal
  const [isFinishOpen, setFinishIsOpen] = useState(false);
  const [totalPoints, setTotalPoints] = useState(0);
  const [bonusPoints, setBonusPoints] = useState(0);

  //Modal when redirect
  const history = useHistory();

  const { id, socialLocationId } = useParams();
  //catch id from path

  const [summary, setSummary] = useState({});
  const [isMetricsOutstandingModalInfoOpen, setIsMetricsOutstandingModalInfoOpen] = useState(false);
  const [isCategoriesModalOpen, setCategoriesModalOpen] = useState(false);

  const createTooltipContent = (type) => (
    <>
      <span className="tooltip__normal-text">
        {t(`actionableConfigs.${type}.tooltip.normalText`)}
      </span>
      <span className="tooltip__text-bold">
        {" "}
        {t(`actionableConfigs.${type}.tooltip.boldText`)}{" "}
      </span>
      <span className="tooltip__normal-text">
        {" "}
        {t(`actionableConfigs.${type}.tooltip.normalText2`)}{" "}
      </span>
      <a
        href="https://a.mktgcdn.com/f/919871/-rS31dpnl_kS7xuYt8LQyH8BxyoIW-ELjCKe_cqfQo0.pdf"
        target="_blank"
        className="tooltip__text-highlighted"
      >
        {t(`actionableConfigs.${type}.tooltip.highlightedText`)}
      </a>
    </>
  );

  const tooltipContents = {
    photos: createTooltipContent('photos'),
    googlePost: createTooltipContent('googlePost'),
    website: createTooltipContent('website'),
    description: createTooltipContent('description')
  };

  const actionableConfigs = [
    {
      key: "categories",
      bonusAmount: () => {
        if (summary.primaryCategoryMatched && summary.secondaryCategoryMatched) {
          return 50;
        } else if (summary.primaryCategoryMatched && !summary.secondaryCategoryMatched) {
          return 20;
        } else if (!summary.primaryCategoryMatched && summary.secondaryCategoryMatched) {
          return 30;
        }
        return 50;
      },
      valueIsOk: () =>
        summary.primaryCategoryMatched && summary.secondaryCategoryMatched,
      getValue: (editedValues, state) => {
        return {
          primaryCategory: summary.categories && summary.categories[0],
          secondaryCategories: 
            (summary.categories && summary.categories.slice(1).length > 0) 
              ? summary.categories.slice(1)
              : [t("actionableConfigs.categories.withoutCategories")],
            };
          },
      getState: () => {
        let stateKey;
        if (summary.categoriesImprovementWasApplied) {
          stateKey = "moderation";
        } else {
          const categoriesAreValid =
            summary.primaryCategoryMatched && summary.secondaryCategoryMatched;
          stateKey = categoriesAreValid ? "success" : "error";
        }
        return stateKey;
      },
      onButtonClick: (setEditableFields, handleApply) => {
        trackEvent("GBP_audit_category_modal_opened", {}, [trackers.MIXPANEL]);
        setCategoriesModalOpen(true);
      },
      isSpecialButton: false,
      titleKey: "actionableConfigs.categories.title",
      highlighted: true,
      iconHeader: true,
      inputType: "categories",
      states: {
        success: {
          status: "success",
          messageKey: "actionableConfigs.categories.states.success.message",
          buttonTextKey:
            "actionableConfigs.categories.states.success.buttonText",
          readOnly: true,
          disabled: true,
        },
        error: {
          status: "error",
          messageKey: "actionableConfigs.categories.states.error.message",
          buttonTextKey: "actionableConfigs.categories.states.error.buttonText",
        },
        moderation: {
          status: "moderation",
          messageKey: "actionableConfigs.categories.states.moderation.message",
          buttonTextKey:
            "actionableConfigs.categories.states.moderation.buttonText",
          readOnly: true,
          disabled: true,
        },
      },
    },
    {
      key: "photoUrl",
      bonusAmount: () => 5,
      valueIsOk: () => summary.photosValueIsOk,
      getValue: (editedValues, state) => {
        const config = actionableConfigs.find(
          (config) => config.key === "photoUrl"
        );
        const placeholderKey =
          config &&
          config.states &&
          config.states[state] &&
          config.states[state].placeholderKey;
        return placeholderKey ? t(placeholderKey) : "";
      },
      getState: () => {
        let stateKey;
        const isModeration = summary.photosWasEdited;

        stateKey = isModeration
          ? "moderation"
          : summary["photosValueIsOk"] === true
          ? "success"
          : "error";
        return stateKey;
      },
      onButtonClick: (setEditableFields, handleApply) => {openPhotosModal(summary.id, handleApply, "ADDITIONAL")},
      titleKey: "actionableConfigs.photos.title",
      highlighted: true,
      tooltip: true,
      tooltipTitle: t("actionableConfigs.photos.tooltip.title"),
      tooltipContent: tooltipContents.photos,
      isSpecialButton: true,
      inputType: "text",
      states: {
        success: {
          status: "success",
          messageKey: "actionableConfigs.photos.states.success.message",
          placeholderKey: "actionableConfigs.photos.states.success.placeholder",
          buttonTextKey: "actionableConfigs.photos.states.success.buttonText",
        },
        error: {
          status: "error",
          messageKey: "actionableConfigs.photos.states.error.message",
          placeholderKey: "actionableConfigs.photos.states.error.placeholder",
          buttonTextKey: "actionableConfigs.photos.states.error.buttonText",
        },
        moderation: {
          status: "moderation",
          messageKey: "actionableConfigs.photos.states.moderation.message",
          buttonTextKey: "actionableConfigs.photos.states.moderation.buttonText",
          readOnly: true,
          disabled: true,
        },
      },
    },
    {
      key: "logo",
      bonusAmount: () => 5,
      valueIsOk: () => summary.profilePhotoValueIsOk,
      getValue: (editedValues, state) => {
        const config = actionableConfigs.find(config => config.key === "logo")
        const placeholderKey = config && config.states && config.states[state] && config.states[state].placeholderKey;
        return placeholderKey ? t(placeholderKey) : "";
      },
      getState: () => {
        let stateKey;
        const isModeration = summary.profilePhotoWasEdited;

        stateKey = isModeration
          ? "moderation"
          : summary["profilePhotoValueIsOk"] === true
          ? "success"
          : "error";
        return stateKey;
      },
      onButtonClick: (setEditableFields, handleApply) => {
        openPhotosModal(summary.id, handleApply, "PROFILE")
      },
      titleKey: "actionableConfigs.logo.title",
      highlighted: true,
      tooltip: false,
      isSpecialButton: true,
      percentage: 5,
      inputType: "logo",
      states: {
        success: {
          status: "success",
          messageKey: "actionableConfigs.logo.states.success.message",
          placeholderKey: "actionableConfigs.logo.states.success.placeholder",
          buttonTextKey: "actionableConfigs.logo.states.success.buttonText",
        },
        error: {
          status: "error",
          messageKey: "actionableConfigs.logo.states.error.message",
          placeholderKey: "actionableConfigs.logo.states.error.placeholder",
          buttonTextKey: "actionableConfigs.logo.states.error.buttonText",
        },
        moderation: {
          status: "moderation",
          messageKey: "actionableConfigs.logo.states.moderation.message",
          buttonTextKey: "actionableConfigs.logo.states.moderation.buttonText",
          readOnly: true,
          disabled: true,
        },
      },
    },
    {
      key: "cover",
      bonusAmount: () => 5,
      valueIsOk: () => summary.coverPhotoValueIsOk,
      getValue: (editedValues, state) => {
        const config = actionableConfigs.find(config => config.key === "cover")
        const placeholderKey = config && config.states && config.states[state] && config.states[state].placeholderKey;
        return placeholderKey ? t(placeholderKey) : "";
      },
      getState: () => {
        let stateKey;
        const isModeration = summary.coverPhotoWasEdited;

        stateKey = isModeration
          ? "moderation"
          : summary["coverPhotoValueIsOk"] === true
          ? "success"
          : "error";
        return stateKey;
      },
      onButtonClick: (setEditableFields, handleApply) => {
        openPhotosModal(summary.id, handleApply, "COVER")
      },
      titleKey: "actionableConfigs.cover.title",
      highlighted: true,
      tooltip: false,
      isSpecialButton: true,
      percentage: 5,
      inputType: "cover",
      states: {
        success: {
          status: "success",
          messageKey: "actionableConfigs.cover.states.success.message",
          placeholderKey: "actionableConfigs.cover.states.success.placeholder",
          buttonTextKey: "actionableConfigs.cover.states.success.buttonText",
        },
        error: {
          status: "error",
          messageKey: "actionableConfigs.cover.states.error.message",
          placeholderKey: "actionableConfigs.cover.states.error.placeholder",
          buttonTextKey: "actionableConfigs.cover.states.error.buttonText",
        },
        moderation: {
          status: "moderation",
          messageKey: "actionableConfigs.cover.states.moderation.message",
          buttonTextKey: "actionableConfigs.cover.states.moderation.buttonText",
          readOnly: true,
          disabled: true,
        },
      },
    },
    {
      key: "postHistoryCount",
      titleKey: "actionableConfigs.googlePost.title",
      bonusAmount: () => 10,
      valueIsOk: () => summary.postHistoryCountValueIsOk,
      getValue: (editedValues, state) => {
        const config = actionableConfigs.find(
          (config) => config.key === "postHistoryCount"
        );
        const placeholderKey =
          config &&
          config.states &&
          config.states[state] &&
          config.states[state].placeholderKey;
        return placeholderKey ? t(placeholderKey) : "";
      },
      getState: () => {
        let stateKey;
        const isModeration = summary.postHistoryWasEdited;

        stateKey = isModeration
          ? "moderation"
          : summary["postHistoryCountValueIsOk"] === true
          ? "success"
          : "error";
        return stateKey;
      },
      onButtonClick: (setEditableFields, handleApply) => {
        openPostModal(summary.id, handleApply);
      },
      highlighted: true,
      tooltip: true,
      tooltipTitle: t("actionableConfigs.googlePost.tooltip.title"),
      tooltipContent: tooltipContents.googlePost,
      isSpecialButton: true,
      inputType: "text",
      states: {
        success: {
          status: "success",
          messageKey: "actionableConfigs.googlePost.states.success.message",
          placeholderKey:
            "actionableConfigs.googlePost.states.success.placeholder",
          buttonTextKey:
            "actionableConfigs.googlePost.states.success.buttonText",
        },
        error: {
          status: "error",
          messageKey: "actionableConfigs.googlePost.states.error.message",
          placeholderKey:
            "actionableConfigs.googlePost.states.error.placeholder",
          buttonTextKey: "actionableConfigs.googlePost.states.error.buttonText",
        },
        moderation: {
          status: "moderation",
          messageKey: "actionableConfigs.googlePost.states.moderation.message",
          buttonTextKey:
            "actionableConfigs.googlePost.states.moderation.buttonText",
          readOnly: true,
          disabled: true,
        },
      },
    },
    {
      key: "phone",
      valueIsOk: () => true,
      getValue: (editedValues, state) => {
        return summary["phone"] || t("actionableConfigs.phone.noPhone");
      },
      getState: () => {
        return "displayOnly";
      },
      bonusAmount: () => 0,
      titleKey: "actionableConfigs.phone.title",
      highlighted: false,
      isSpecialButton: false,
      inputType: "display",
      states: {
        displayOnly: {
          status: "info",
          message: "",
        },
      },
    },
    {
      key: "website",
      titleKey: "actionableConfigs.website.title",
      valueIsOk: () => summary.websiteValueIsOk,
      bonusAmount: () => 15,
      getValue: (editedValues, state) => {
        return editedValues.website;
      },
      urlValidity: (url) => {return url && url.length > 0 && isValidUrl(url)},
      getState: () => {
        let stateKey;
        const isModeration = summary.websiteWasEdited;
        stateKey = isModeration
          ? "moderation"
          : summary["websiteValueIsOk"] === true
          ? "success"
          : "error";
        return stateKey;
      },
      onButtonClick: (setEditableFields, handleApply) => {
        setEditableFields((prev) => ({ ...prev, website: true }));
      },
      highlighted: false,
      isSpecialButton: false,
      tooltip: true,
      tooltipTitle: t("actionableConfigs.website.tooltip.title"),
      tooltipContent: tooltipContents.website,
      inputType: "text",
      states: {
        success: {
          status: "success",
          messageKey: "actionableConfigs.website.states.success.message",
          placeholderKey:
            "actionableConfigs.website.states.success.placeholder",
          buttonTextKey: "actionableConfigs.website.states.success.buttonText",
        },
        error: {
          status: "error",
          messageKey: "actionableConfigs.website.states.error.message",
          placeholderKey: "actionableConfigs.website.states.error.placeholder",
          buttonTextKey: "actionableConfigs.website.states.error.buttonText",
        },
        moderation: {
          status: "moderation",
          messageKey: "actionableConfigs.website.states.moderation.message",
          buttonTextKey:
            "actionableConfigs.website.states.moderation.buttonText",
          readOnly: true,
          disabled: true,
        },
      },
    },
    {
      key: "description",
      titleKey: "actionableConfigs.description.title",
      bonusAmount: () => 10,
      valueIsOk: () => summary.descriptionValueIsOk,
      getValue: (editedValues, state) => {
        return editedValues.description;
      },
      minLength: 150,
      getState: () => {
        let stateKey;
        const isModeration = summary.descriptionWasEdited;
        stateKey = isModeration
          ? "moderation"
          : summary["descriptionValueIsOk"] === true
          ? "success"
          : "error";
        return stateKey;
      },
      onButtonClick: (setEditableFields, handleApply) => {
        setEditableFields((prev) => ({ ...prev, description: true }));
      },
      highlighted: false,
      isSpecialButton: false,
      tooltip: true,
      tooltipTitle: t("actionableConfigs.description.tooltip.title"),
      tooltipContent: tooltipContents.description,
      inputType: "paragraph",
      states: {
        success: {
          status: "success",
          messageKey: "actionableConfigs.description.states.success.message",
          placeholderKey:
            "actionableConfigs.description.states.success.placeholder",
          buttonTextKey:
            "actionableConfigs.description.states.success.buttonText",
          readOnly: false,
        },
        error: {
          status: "error",
          messageKey: "actionableConfigs.description.states.error.message",
          placeholderKey:
            "actionableConfigs.description.states.error.placeholder",
          buttonTextKey:
            "actionableConfigs.description.states.error.buttonText",
          readOnly: false,
        },
        moderation: {
          status: "moderation",
          messageKey: "actionableConfigs.description.states.moderation.message",
          buttonTextKey:
            "actionableConfigs.description.states.moderation.buttonText",
          readOnly: true,
          disabled: true,
        },
      },
    },
    {
      key: "address",
      titleKey: "actionableConfigs.address.title",
      bonusAmount: () => 0,
      valueIsOk: () => true,
      getValue: (editedValues, state) => {
        return summary["address"];
      },
      getState: () => {
        return "displayOnly";
      },
      isSpecialButton: false,
      highlighted: false,
      inputType: "display",
      states: {
        displayOnly: {
          status: "info",
          message: "",
        },
      },
    },
  ];

  const translatedConfigs = actionableConfigs.map((config) =>
    translateConfig(config, t)
  );

  useEffect(() => {
    // Evento de Mixpanel GBP_audit_regular_location_view
    trackEvent("GBP_audit_regular_location_view", {}, [trackers.MIXPANEL]);
  }, []);

  useEffect(() => {
    if (id) {
      getGoogleBusinessProfile(id)
        .then((res) => {
          if (res.data.data) {
            setSummary(res.data.data);
          }
        })
        .catch((error) => {
          console.error("Error fetching Google Business Profile:", error);
        });
    }
  }, [id]);

  const isValidUrl = (url) => {

    const BLACKLISTED_WEBSITES = [
      "facebook.com",
      "fb.com",
      "fbcdn.net",
      "m.facebook.com",
      "instagram.com",
      "ig.me",
      "redd.it",
      "reddit.com",
      "redditmedia.com",
      "tiktok.com",
      "m.tiktok.com"
    ];

    if (!url || url.length === 0) return false;
    
  
    const urlRegex = new RegExp(
      '^https://' +                    
      '([a-zA-Z0-9][a-zA-Z0-9-]*[a-zA-Z0-9]\\.)+' +
      '[a-zA-Z]{2,}' +                 
      '(:[0-9]+)?' +                 
      '(/[a-zA-Z0-9-._~:/?#[\\]@!$&\'()*+,;=]*)?$'
    );

    try {
      
      if (!urlRegex.test(url)) return false;
      
     
      const urlInstance = new URL(url);
      
      if (BLACKLISTED_WEBSITES.includes(urlInstance.hostname)) return false;

      if (urlInstance.protocol !== 'https:') return false;

      if (urlInstance.hostname.length > 253) return false;
      
      return true;
    } catch (e) {
      return false;
    }
  };

  const modalFinishClose = () => {
    setFinishIsOpen(false);
  };

  return (
    <div className="gbp-details-container">
      <div className="grid-container">
        <div className="title-text d-flex-column">
          <button
            className="button-back mb-4"
            onClick={() => history.push(`/localseo/gbp`)}
          >
            {t("buttonBack")}
          </button>
          <div className="d-flex-row gap-2">
            <img
              src="/static/img/gbp-icon.svg"
              alt={t("alt.icon")}
              width="44"
              height="44"
            />
            <span
              style={{
                fontFamily: "Open Sans",
                fontSize: "28px",
                color: "1A1A1A",
                fontWeight: "bold",
              }}
            >
              {summary.name}
            </span>
          </div>
          <div className="mt-0">
            <div className="verification-container">
              <div className="verification-left">
                <svg
                  width="14"
                  height="15"
                  viewBox="0 0 14 15"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M6.65619 0.945559C6.84902 0.762939 7.15098 0.762939 7.34381 0.945559C8.68832 2.21887 10.5025 2.99909 12.5 2.99909C12.5318 2.99909 12.5635 2.9989 12.5952 2.9985C12.8134 2.99579 13.0082 3.13496 13.0764 3.3423C13.3514 4.17881 13.5 5.07211 13.5 5.99912C13.5 9.96047 10.7906 13.2877 7.12463 14.2313C7.04288 14.2523 6.95713 14.2523 6.87537 14.2313C3.20937 13.2877 0.5 9.96047 0.5 5.99912C0.5 5.07211 0.648582 4.17881 0.92361 3.3423C0.99178 3.13496 1.18656 2.99579 1.40481 2.9985C1.43649 2.9989 1.46822 2.99909 1.5 2.99909C3.4975 2.99909 5.31168 2.21887 6.65619 0.945559ZM7 4.99908C7.27614 4.99908 7.5 5.22294 7.5 5.49908V7.99908C7.5 8.27523 7.27614 8.49908 7 8.49908C6.72386 8.49908 6.5 8.27523 6.5 7.99908V5.49908C6.5 5.22294 6.72386 4.99908 7 4.99908ZM7 9.49908C6.72386 9.49908 6.5 9.72294 6.5 9.99908V10.0041C6.5 10.2802 6.72386 10.5041 7 10.5041H7.005C7.28114 10.5041 7.505 10.2802 7.505 10.0041V9.99908C7.505 9.72294 7.28114 9.49908 7.005 9.49908H7Z"
                    fill={summary.verified ? "#1EB8FF" : "gray"}
                  />
                </svg>
                <span
                  className={
                    "verification-text ml-1 " +
                    (summary.verified ? "text-blue" : "text-gray")
                  }
                >
                  {summary.verified
                    ? t("categories.modal.verified")
                    : t("categories.modal.notVerified")}
                </span>
              </div>

              {!summary.verified && (
                <div className="verification-right">
                  <a
                    href="https://youtu.be/j9tug-G7odI"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="tutorial-link"
                  >
                    <svg
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M8 0.5C3.85786 0.5 0.5 3.85786 0.5 8C0.5 12.1421 3.85786 15.5 8 15.5C12.1421 15.5 15.5 12.1421 15.5 8C15.5 3.85786 12.1421 0.5 8 0.5ZM8.75 11.5C8.75 11.9142 8.41421 12.25 8 12.25C7.58579 12.25 7.25 11.9142 7.25 11.5V7.5C7.25 7.08579 7.58579 6.75 8 6.75C8.41421 6.75 8.75 7.08579 8.75 7.5V11.5ZM8 5.5C7.44772 5.5 7 5.05228 7 4.5C7 3.94772 7.44772 3.5 8 3.5C8.55228 3.5 9 3.94772 9 4.5C9 5.05228 8.55228 5.5 8 5.5Z"
                        fill="#1EB8FF"
                      />
                    </svg>
                    <span className="tutorial-text">
                      {t("tutorial.button")}
                    </span>
                  </a>
                </div>
              )}
            </div>
          </div>
          <div className="">
            <ModalFinish
              isOpen={isFinishOpen}
              toggle={modalFinishClose}
              title={t("modalFinish.title")}
              subtitle={t("modalFinish.subtitle")}
              bonus={bonusPoints}
            />
            {summary && (
              <>
                {(() => {
                  const { outstanding, scoreInfoLink } =
                    metricsOutstandingConfiguration(summary, t, () =>
                      setIsMetricsOutstandingModalInfoOpen(true)
                    );

                  return (
                    <UxMetricOutstandingComponent
                      {...outstanding}
                      scoreInfoLink={scoreInfoLink}
                    />
                  );
                })()}

                <ScoreScreen
                  isOpen={isMetricsOutstandingModalInfoOpen}
                  toggle={() => setIsMetricsOutstandingModalInfoOpen(!isMetricsOutstandingModalInfoOpen)}
                  t={t}
                />
              </>
            )}
          </div>
          {summary && Object.keys(summary).length > 0 ? (
            <RenderActionableCards
              summary={summary}
              actionableConfigs={translatedConfigs}
              setSummary={setSummary}
              setCategoriesModalOpen={setCategoriesModalOpen}
              iconHeader={Icons.iconHeader()}
              setTotalPoints={setTotalPoints}
              setBonusPoints={setBonusPoints}
              bonusPoints={bonusPoints}
              totalPoints={totalPoints}
              socialLocationId={socialLocationId}
              t={t}
              setFinishIsOpen={setFinishIsOpen}
              isCategoriesModalOpen={isCategoriesModalOpen}
              trackEvent={trackEvent}
            />
          ) : (
            <div>{t("noData")}</div>
          )}
        </div>

        <div className="second-column">
          <div className="row row-9">
            <p className="card-description-video w-100">{t("titleTutorial")}</p>
            <div className="card-video">
              <iframe
                width="100%"
                height="357"
                src="https://www.youtube.com/embed/Qx8ElTKfE-g?si=nfr9e32wx-bb4LDY&amp;start=1"
                title="YouTube video player"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                referrerPolicy="strict-origin-when-cross-origin"
                allowFullScreen
              ></iframe>
            </div>
          </div>
          <div className="">
            <CardList cardsData={cardsData(t)} layout="columns" />
          </div>
        </div>
        <button
          className="save-and-return"
          onClick={() => history.push(`/localseo/gbp`)}
        >
          {t("saveAndReturn")}
        </button>
      </div>
      <PostModalContainer />
      <PhotosModalContainer />
    </div>
  );
};

BusinessProfilesDetails.translations = {
  ...translations,
};

export const BusinessProfilesDetailsContainer = withEventTracking(translate(BusinessProfilesDetails));
