import React from "react";
import { Modal, ModalHeader, ModalBody } from "botbit-ui-components";
import "./ScoreScreen.scss";
import CardList from "../details/CardList/CardList";
import { translations } from "../../../../config/translations/GoogleBusinessProfile/Score";
import translate from "../../../../config/translations/translate";

const ScoreScreen = ({ isOpen, toggle, t}) => {
  const cardsData = [
    {
      title: t('analysis.title'),
      description: t('analysis.description'),
      icon: (
        <svg
          width="40"
          height="40"
          viewBox="0 0 20 22"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M5.11428 13.7329C4.16447 12.3439 3.60344 10.6292 3.60344 8.7738C3.60344 4.14248 7.09894 0.388062 11.4109 0.388062C15.7228 0.388062 19.2183 4.14248 19.2183 8.7738C19.2183 13.4051 15.7228 17.1595 11.4109 17.1595C9.68291 17.1595 8.08608 16.5566 6.79276 15.536L6.7938 15.5368C6.75838 15.5884 6.71892 15.6378 6.67544 15.6845L2.05049 20.652C1.58142 21.1558 0.820896 21.1558 0.351822 20.652C-0.117252 20.1482 -0.117252 19.3313 0.351822 18.8275L4.97677 13.86C5.02026 13.8133 5.06625 13.7709 5.11428 13.7329ZM4.80458 8.7738C4.80458 12.6926 7.76231 15.8694 11.4109 15.8694C15.0594 15.8694 18.0171 12.6926 18.0171 8.7738C18.0171 4.85499 15.0594 1.67817 11.4109 1.67817C7.76231 1.67817 4.80458 4.85499 4.80458 8.7738Z"
            fill="#4285F4"
          />
        </svg>
      ),
    },
    {
      title: t('scoring.title'),
      description: t('scoring.description'),
      icon: (
        <svg
          width="40"
          height="40"
          viewBox="0 0 40 40"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M10.002 27.5H5.00195V35H10.002V27.5ZM22.502 17.5H17.502V35H22.502V17.5ZM35.002 5V35H30.002V5L35.002 5ZM30.002 2.5C28.6212 2.5 27.502 3.61929 27.502 5V35C27.502 36.3807 28.6212 37.5 30.002 37.5H35.002C36.3827 37.5 37.502 36.3807 37.502 35V5C37.502 3.61929 36.3827 2.5 35.002 2.5H30.002ZM15.002 17.5C15.002 16.1193 16.1212 15 17.502 15H22.502C23.8827 15 25.002 16.1193 25.002 17.5V35C25.002 36.3807 23.8827 37.5 22.502 37.5H17.502C16.1212 37.5 15.002 36.3807 15.002 35V17.5ZM2.50195 27.5C2.50195 26.1193 3.62124 25 5.00195 25H10.002C11.3827 25 12.502 26.1193 12.502 27.5V35C12.502 36.3807 11.3827 37.5 10.002 37.5H5.00195C3.62124 37.5 2.50195 36.3807 2.50195 35V27.5Z"
            fill="#4285F4"
          />
        </svg>
      ),
    },
    {
      title: t('classification.title'),
      description: t('classification.description'),
      icon: (
        <svg
          width="40"
          height="36"
          viewBox="0 0 40 36"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M19.8461 3.15252C19.8895 3.1284 19.9437 3.11328 20.0039 3.11328C20.064 3.11328 20.1183 3.1284 20.1617 3.15252C20.1992 3.17336 20.2487 3.21143 20.2985 3.29609L37.4409 32.4628C37.53 32.6144 37.5282 32.7732 37.4462 32.9211C37.4051 32.9953 37.3536 33.0442 37.3094 33.0716C37.2726 33.0944 37.2251 33.1133 37.1462 33.1133H2.86148C2.78258 33.1133 2.73511 33.0944 2.6983 33.0716C2.65407 33.0442 2.60264 32.9953 2.56152 32.9211C2.47951 32.7732 2.47771 32.6144 2.56687 32.4628L19.7093 3.29609C19.759 3.21143 19.8086 3.17336 19.8461 3.15252ZM22.4538 2.02934C21.3441 0.141263 18.6636 0.141263 17.5539 2.02934L0.411564 31.196C-0.731017 33.14 0.640697 35.6133 2.86148 35.6133H37.1462C39.367 35.6133 40.7387 33.14 39.5962 31.196L22.4538 2.02934Z"
            fill="#4285F4"
          />
          <path
            d="M17.5039 28.1133C17.5039 26.7326 18.6231 25.6133 20.0039 25.6133C21.3846 25.6133 22.5039 26.7326 22.5039 28.1133C22.5039 29.494 21.3846 30.6133 20.0039 30.6133C18.6231 30.6133 17.5039 29.494 17.5039 28.1133Z"
            fill="#4285F4"
          />
          <path
            d="M17.7488 13.1009C17.6156 11.769 18.6615 10.6133 20 10.6133C21.3385 10.6133 22.3844 11.769 22.2512 13.1009L21.3744 21.8695C21.3038 22.5756 20.7096 23.1133 20 23.1133C19.2904 23.1133 18.6962 22.5756 18.6256 21.8695L17.7488 13.1009Z"
            fill="#4285F4"
          />
        </svg>
      ),
    },
  ];

  const tableData = [
    {
      value: "+30%",
      description: t('criteriaSection.criteria.primaryCategory'),
    },
    {
      value: "+20%",
      description: t('criteriaSection.criteria.secondaryCategories'),
    },
    {
      value: "+10%",
      description: t('criteriaSection.criteria.description'),
    },
    {
      value: "+10%",
      description: t('criteriaSection.criteria.googlePosts'),
    },
    {
      value: "+15%",
      description: t('criteriaSection.criteria.website'),
    },
    {
      value: "+5%",
      description: t('criteriaSection.criteria.photos'),
    },
    {
      value: "+5%",
      description: t('criteriaSection.criteria.cover'),
    },
    {
      value: "+5%",
      description: t('criteriaSection.criteria.logo'),
    },

  ];

  const scoreDataDescription = [
    {
      icon: (
        <svg
          width="36"
          height="13"
          viewBox="0 0 36 13"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect
            y="12.5"
            width="12"
            height="36"
            rx="2"
            transform="rotate(-90 0 12.5)"
            fill="#D80027"
          />
        </svg>
      ),
      description: t('scoreStatus.levels.low'),
    },
    {
      icon: (
        <svg
          width="36"
          height="13"
          viewBox="0 0 36 13"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect
            y="12.5"
            width="12"
            height="36"
            rx="2"
            transform="rotate(-90 0 12.5)"
            fill="#FF9407"
          />
        </svg>
      ),
      description: t('scoreStatus.levels.medium'),
    },
    {
      icon: (
        <svg
          width="36"
          height="13"
          viewBox="0 0 36 13"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect
            y="12.5"
            width="12"
            height="36"
            rx="2"
            transform="rotate(-90 0 12.5)"
            fill="#00974E"
          />
        </svg>
      ),
      description: t('scoreStatus.levels.high'),
    },
  ];

  return (
    <Modal isOpen={isOpen} toggle={toggle}>
      <ModalHeader toggle={toggle}></ModalHeader>
      <ModalBody>
        <div className="score-screen-container">
          <div className="cards-container">
            <CardList cardsData={cardsData} layout="rows" />
          </div>

          <div className="row col-12 mb-4">
            <div className="col-12 p-0 mock-component">
              <div className="background">
                <div className="score-screen-container">
                  <div className="score-container-current">
                    <div className="score-icon">
                      <svg
                        width="44"
                        height="44"
                        viewBox="0 0 44 44"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <rect width="44" height="44" rx="4" fill="#9291A5" />
                        <path
                          d="M25.4602 14.1464C25.3664 14.0527 25.2392 14 25.1066 14H18.8934C18.7608 14 18.6336 14.0527 18.5398 14.1464L14.1464 18.5398C14.0527 18.6336 14 18.7608 14 18.8934V25.1066C14 25.2392 14.0527 25.3664 14.1464 25.4602L18.5398 29.8536C18.6336 29.9473 18.7608 30 18.8934 30H25.1066C25.2392 30 25.3664 29.9473 25.4602 29.8536L29.8536 25.4602C29.9473 25.3664 30 25.2392 30 25.1066V18.8934C30 18.7608 29.9473 18.6336 29.8536 18.5398L25.4602 14.1464ZM22 18C22.5354 18 22.9538 18.4623 22.9005 18.995L22.5498 22.5025C22.5215 22.7849 22.2838 23 22 23C21.7162 23 21.4785 22.7849 21.4502 22.5025L21.0995 18.995C21.0462 18.4623 21.4646 18 22 18ZM22.0015 24C22.5538 24 23.0015 24.4477 23.0015 25C23.0015 25.5523 22.5538 26 22.0015 26C21.4493 26 21.0015 25.5523 21.0015 25C21.0015 24.4477 21.4493 24 22.0015 24Z"
                          fill="white"
                        />
                      </svg>
                    </div>
                    <div className="score-info">
                      <div className="score-text">
                        {t('scoreStatus.currentScore')}
                      </div>
                      <div className="progress-bar">
                        <svg
                          width="800"
                          height="7"
                          viewBox="0 0 907 7"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <rect
                            x="8.89844"
                            y="0.257812"
                            width="500"
                            height="6.47976"
                            rx="3"
                          />
                          <rect
                            y="0.257812"
                            width="700"
                            height="6.47976"
                            rx="3"
                            fill="#9291A5"
                          />
                        </svg>
                      </div>
                    </div>
                  </div>
                  <div className="item-list">
                    {scoreDataDescription.map((item, index) => (
                      <div key={index} className="item-description">
                        <div className="item-icon">{item.icon}</div>
                        <h6 className="item-description">{item.description}</h6>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-12">
              <h3 className="font-bold-600">
              {t('criteriaSection.title')}
              </h3>
              <table className="w-100 p-0">
                <tbody className="row-tbody">
                  {tableData.map((row, index) => (
                    <tr
                      key={index}
                      className={
                        index % 2 === 0
                          ? "even-row row-display"
                          : "odd-row row-display"
                      }
                    >
                      <td className="row-value no-border">{row.value}</td>
                      <td className="row-description no-border">
                        {row.description}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};

ScoreScreen.translations = {
  ...translations,
};

export default translate(ScoreScreen);