import React from "react";
import { Modal, ModalHeader, ModalBody } from "botbit-ui-components";
import Icons from "../Icons/Icons";
  
const ModalFinish = ({ isOpen, toggle, title, subtitle, bonus }) => {
  return (
    <Modal isOpen={isOpen} toggle={toggle} className="modal-finish">
      <ModalHeader toggle={toggle} className="pb-0"></ModalHeader>
      <ModalBody className="modal-finish-header pt-0">
        <div>
          <Icons.SvgModalFinish />
        </div>
        <div>
          <p className="modal-finish-bonus">+{bonus}%</p>
        </div>
        <h3 className="modal-finish-title mb-0">{title}</h3>
        <p className="modal-finish-subtitle w-75">{subtitle}</p>
      </ModalBody>
    </Modal>
  );
};

export default ModalFinish;
