import React from "react";
import translate from "../../../config/translations/translate";
import "./styles.css";
import { MDBProgress } from "mdbreact";

const LoadingPage = ({ t, progress }) => {

  return (
    <div className="gbp-container loading-page-container">
      <div className="loading-page-container">
        <div className="loading-page-header">
          <h1
            style={{
              fontFamily: "Open Sans",
              fontWeight: "bold",
              fontSize: "48px",
              color: "black",
            }}
          >
            {t("title")}
          </h1>
          <h4
            style={{
              fontFamily: "Open Sans",
              fontWeight: "normal",
              fontSize: "18px",
              color: "black",
            }}
          >
            {t("subtitle")}
          </h4>
        </div>
        <div className="w-100 d-flex justify-content-center">
          {/* <Spinner/> */}
          <MDBProgress

            value={progress}
            min={0}
            max={100}
            className="w-50 progress-bar-container"
            animated={true}
            preloader={true}
			barClassName="color-progress-bar"
          >
            <span className="p-1 font-bold">{progress}%</span>
          </MDBProgress>
        </div>
      </div>
    </div>
  );
};

LoadingPage.translations = {
  title: {
    en: "Analyzing...",
    es: "Analizando...",
  },
  subtitle: {
    en: "We are analyzing all your stores to help you improve your positioning!",
    es: "Estamos analizando todos tus locales para ayudarte a mejorar tu posicionamiento!",
  },
};

export const LoadingPageContainer = translate(
  LoadingPage
);
