import React, { useState } from 'react';
import SocialLocationPostContainer from '../../../SocialLocationPostContainer';

let setModalOpen;
let publicHandleApply; //el handleapply de index. (details)

export const openPostModal = (socialLocationId, handleApply) => {
  publicHandleApply = handleApply;
  if (setModalOpen) {
    setModalOpen(socialLocationId);
  }
};

const PostModalContainer = () => {
  const [isPostModalOpen, setIsPostModalOpen] = useState();
  setModalOpen = setIsPostModalOpen;

  return (
    <SocialLocationPostContainer
      modal={{
        isOpen: isPostModalOpen !== undefined,
        toggle: () => setIsPostModalOpen(undefined),
      }}
      socialLocationId={isPostModalOpen}
      handleApply={publicHandleApply}
    />
  );
};

export default PostModalContainer;