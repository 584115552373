import React from 'react';
import { envConfig } from '../../../config/config.js';
import { translate } from 'react-i18next';
import LogoV2 from '../../../commons/components/utils/LogoV2.js';

class NavigationHeader extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			profileOpen: false
		};
		this.handleClickOutside = this.handleClickOutside.bind(this);
		this.setWrapperRef = this.setWrapperRef.bind(this);
	}

	expandMenu = () => {
		this.setState({
			profileOpen: !this.state.profileOpen
		});
	};

	setWrapperRef(node) {
		this.wrapperRef = node;
	}

	componentDidMount() {
		document.addEventListener('mousedown', this.handleClickOutside);
	}

	componentWillUnmount() {
		document.removeEventListener('mousedown', this.handleClickOutside);
	}

	handleClickOutside(event) {
		if (this.wrapperRef && !this.wrapperRef.contains(event.target))
			this.setState({
				profileOpen: false
			});
	}


	render() {
		const { admin } = this.props;
		// TODO: ver con Gonza
		if (admin == null)
			return null;

		const { profileOpen } = this.state;
		const divClass = profileOpen ? "dropdown profile-element open" : "dropdown profile-element";
		// const avatar = envConfig.staticImgUrl + "avatars/" + admin.avatar;
		// const settingsAccount = `/settings/administrators/${admin.id}`;
		// const { t } = this.props;

		return (
			<li className="nav-header">
				<div ref={this.setWrapperRef} className={divClass} align="center" >
					<span>
						{/* <img alt="" src={envConfig.rayLogoUrl} className='nav-header-logo' /> */}
						<LogoV2 width={"100%"} height={"100%"} />
					</span>


					{/* <span>
						<img alt="" className="img-circle nav-header-avatar" id="menuAvatar" src={avatar} />
					</span> */}
					{/* eslint-disable-next-line */}
					{/* <a data-toggle="dropdown" className="dropdown-toggle" aria-expanded={profileOpen}>
						<span className="clear">
							<span className="block m-t-xs">
								<strong className="font-bold">{admin.name} {admin.lastName}</strong>
							</span>
							<span className="text-muted text-xs block" onClick={this.expandMenu}>
								{admin.role}
								&nbsp;
								<b className="caret" />
							</span>
						</span>
					</a>
					{this.state.profileOpen === true &&
						<div className="dropdown-menu">
							<a className="dropdown-item" href={settingsAccount}>{t("user.profile")}</a>
							<div className="dropdown-divider" />
							<a className="dropdown-item" onClick={this.props.handleLogout} href='#'>{t("logout.logout")}</a>
						</div>
					} */}
				</div>
			</li>
		)
	}
}

export default translate('common')(NavigationHeader)
