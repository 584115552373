// import { faChartBar as metricsIcon } from '@fortawesome/free-solid-svg-icons'
import { faFileAlt as reportsIcon } from '@fortawesome/free-regular-svg-icons'
import { faGem as marketingIcon } from '@fortawesome/free-regular-svg-icons'
import { faSitemap as relationshipsIcon } from '@fortawesome/free-solid-svg-icons'
// import { faCalendarAlt as reservationsIcon } from '@fortawesome/free-solid-svg-icons'
// import { faCog as configIcon } from '@fortawesome/free-solid-svg-icons'
import { faComments as commentsIcon } from '@fortawesome/free-solid-svg-icons';
import { faStar as starIcon } from '@fortawesome/free-solid-svg-icons';
import { faQuestionCircle as helpIcon } from '@fortawesome/free-solid-svg-icons';
import { faHome as homeIcon } from '@fortawesome/free-solid-svg-icons'
import { faList as listIcon } from '@fortawesome/free-solid-svg-icons' 
import { faMapPin as localSEO } from '@fortawesome/free-solid-svg-icons' 

import { envConfig } from './config.js';

import { Feature } from '../commons/enums/Feature.js';

export const comparePaths = (pathWithParams, realPath) => {
	let pathWithParamsSplitted = pathWithParams.split('/');
	let realPathSplitted = realPath.split('/');

	let areEqual = (pathWithParamsSplitted.length === realPathSplitted.length);

	if (areEqual) {
		pathWithParamsSplitted.forEach((element, index) => {
			if (element.indexOf(':') === -1 && element !== realPathSplitted[index]) {
				areEqual = false;
			}
		});
	}
	return areEqual;
}

export const getPageTitleParams = (url) => {
	var path = ["Home"];
	var title;
	var showPageTitle = true;
	var notVisible = false;
	let menuConf = menuConfig();


	for (const item of menuConf.menu) {
		for (const subitem of item.children) {
			if (comparePaths(subitem.url, url)) {
				path.push("menu." + item.text + ".text");
				path.push("menu." + subitem.text + ".text");
				title = "menu." + subitem.text + ".title";
				if (subitem.showPageTitle !== undefined && subitem.showPageTitle === false) showPageTitle = false;
				if (subitem.notVisible) notVisible = true;
			}
		}

	}

	return {
		title: title,
		path: path,
		showPageTitle: showPageTitle,
		notVisible: notVisible,
	}
}

export const getMenuFromLocation = (location) => {
	let menuConf = menuConfig();

	for (const item of menuConf.menu) {
		for (const subitem of item.children) {
			if (subitem.url === location) {
				return subitem;
			}
		}

	}
}

export const menuConfig = (selectedCompany = { id: undefined }, admin = { operations: []}) => {
	// console.log("selectedCompany", selectedCompany)
	return {
		menu: [
			{
				key: "metrics_control_panel",
				text: 'home',
				// icon: metricsIcon,
				icon: homeIcon,
				url: "/dashboard",
				operations: "STORE_ANALYTICS__VIEW",
				noChildrenMenu: true,
				children: [
					{
						showPageTitle: false,
						key: "metrics_control_panel",
						text: 'controlPanel',
						operations: "STORE_ANALYTICS__VIEW",
						url: "/dashboard"
					},
				]
			},
			{
				key: "localSEO",
				text: 'localSEO',
				icon: localSEO,
				children: [
					{
						key: "gbp_audit",
						text: 'gbpAudit',
						url: "/localseo/gbp",
						operations: "GBP__VIEW",
					},
					{
						key: "localPost",
						text: 'localPost',
						url: "/localseo/posts",
						operations: "SOCIAL_LOCATION_POST__EXECUTE",
					},
				]
			},
			{
				key: "checklist",
				text: 'checklist',
				icon: listIcon,
				url: "/checklist",
				operations: "STORE_ANALYTICS__VIEW",
				noChildrenMenu: true,
				notVisible: !admin.objectAccess || admin.objectAccess !== 'ALL',
				children: [
					{
						showPageTitle: false,
						key: "checklist",
						text: 'checklist',
						operations: "STORE_ANALYTICS__VIEW",
						url: "/checklist"
					},
				]
			},	
			{
				key: "feedback",
				text: 'feedback',
				icon: starIcon,
				children: [
					{
						key: "feedback_reviews",
						text: 'reviews',
						url: "/feedback/reviews",
						operations: "REVIEWS__VIEW",
						product: 'feedback',
						showPageTitle: false
					},
					{
						key: "feedback_reviews",
						text: 'reviews',
						url: "/feedback/reviews/:id",
						operations: "REVIEWS__VIEW",
						product: 'feedback',
						showPageTitle: false,
						notVisible: true
					},
					{
						key: "feedback_metrics",
						text: 'feedbackMetrics',
						url: "/feedback/metrics",
						operations: "REVIEWS__VIEW",
						product: 'feedback',
						showPageTitle: false
					},
					{
						text: 'connectedStores',
						url: "/feedback/connectedstores",
						operations: "REVIEWS__VIEW",
						product: 'feedback',
						notVisible: true,
						showPageTitle: false
					},
					{
						key: "feedback_nps",
						text: 'nps',
						url: "/feedback/nps",
						operations: "REVIEWS__VIEW",
						product: 'feedback',
						showPageTitle: false,
						planOperations: ["REVIEWS_NPS__VIEW"],
						feature: Feature.NPS_DASHBOARD
					},
					{
						key: "feedback_replytemplates",
						text: 'replyTemplates',
						url: "/feedback/replytemplates",
						operations: "REVIEWS__VIEW",
						product: 'feedback',
						notVisible: true,
						showPageTitle: false
					},
					{
						key: "feedback_autoreplyrules",
						text: 'autoreplyrules',
						url: "/feedback/autoreplyrules",
						operations: "REVIEWS__VIEW",
						product: 'feedback',
						notVisible: true,
						showPageTitle: false
					},
					{
						text: 'socialaccounts',
						url: "/socialaccounts",
						operations: "STORES__EDIT",
						notVisible: true,
						showPageTitle: false
					},
					{
						text: 'widget',
						url: "/feedback/widget",
						operations: "REVIEWS_WIDGET__VIEW",
						product: 'feedback',
						notVisible: true,
						showPageTitle: false,
						planOperations: ["REVIEWS_WIDGET__VIEW"],
						feature: Feature.REVIEWS_WIDGET
					},
					{
						key: "feedback_survey",
						text: 'surveyStats',
						url: "/feedback/survey",
						operations: "REVIEWS__VIEW",
						product: 'feedback',
						showPageTitle: false,
						notVisible: !selectedCompany.npsCustomQuestions || selectedCompany.npsCustomQuestions.length === 0
					}
				]
			},
			{
				key: "feedback_sentiment",
				text: 'sentiments',
				url: "/sentiment",
				noChildrenMenu: true,
				icon: "heart",
				notVisible: !selectedCompany.operations || !selectedCompany.operations.includes("SENTIMENT_ANALYSIS__VIEW"),
				children: [{
					showPageTitle: false,
					key: "feedback_sentiment",
					text: 'sentiments',
					url: "/sentiment",
					operations: null,
				}]
			},
			{
				key: "inbox",
				text: 'inbox',
				icon: commentsIcon,
				notVisible: selectedCompany.goal !== 'organizeConversations',
				children: [
					{
						key: "inbox_dashboard",
						text: 'inbox_dashboard',
						url: "/chat/dashboard",
						operations: "STORE_ANALYTICS__VIEW",
						showPageTitle: false,
					},
					{
						key: "inbox",
						text: 'inbox',
						url: "/chat",
						operations: "CONVERSATION__REPLY",
						showPageTitle: false,
					},
					{
						key: "issues",
						text: 'issues',
						url: "/issues/dashboard",
						showPageTitle: false,
					}
				]
			},
			{
				key: "marketing",
				text: 'marketing',
				icon: marketingIcon,
				children: [
					{
						key: "marketing_campaigns",
						text: 'campaigns',
						url: "/campaigns",
						operations: "SEGMENTS__VIEW",
						product: 'marketing',
						possibleChildren: ["/campaigns/:id", "/campaigns/:id/edit", "/campaigns/:id/create"],
						showPageTitle: false,
						planOperations: ["CAMPAIGNS__VIEW"],
						feature: Feature.CAMPAIGNS
					},
					{
						text: 'segments',
						url: "/segments",
						operations: "SEGMENTS__VIEW",
						product: 'marketing',
						showPageTitle: false
					},
					{
						key: "marketing_campaigns_analytics",
						text: 'campaigns_analytics',
						url: "/campaigns/:id",
						showPageTitle: false,
						notVisible: true
					},
					{
						text: 'campaigns_maximal_editor',
						url: "/campaigns/create",
						showPageTitle: false,
						notVisible: true
					},
					{
						text: 'campaigns_maximal_editor',
						url: "/campaigns/:id/create",
						showPageTitle: false,
						notVisible: true
					},
					{
						key: "marketing_campaigns_maximal_editor_edit",
						text: 'campaigns_maximal_editor',
						url: "/campaigns/:id/edit",
						showPageTitle: false,
						notVisible: true
					},
				]
			},
			{
				key: "clients",
				text: "clients",
				icon: 'users',
				url: '/crm',
				noChildrenMenu: true,
				children: [
					{
						key: "relationships_customers",
						text: 'clients',
						url: "/crm",
						product: 'marketing',
						operations: "USERS__VIEW",
						possibleChildren: ["/crm/user/:id"],
						showPageTitle: false
					},
				]
			},
			{
				key: "reports",
				text: 'reports',
				icon: reportsIcon,
				children: [
					{
						text: 'activity',
						url: "/adminActivity",
						operations: "ADMIN_EVENTS__VIEW",
						showPageTitle: false
					},
					{
						key: "reports_emailActivity",
						text: 'emailActivity',
						url: "/emailActivity",
						operations: "COMPANIES__EDIT",
						product: 'marketing',
						feature: Feature.CAMPAIGNS
					},
					{
						key: "reports_leaderboard",
						text: 'leaderboard',
						url: "/leaderboard",
						// operations: "LEADERBOARD__VIEW",
						showPageTitle: false,
						product: "feedback",
						notVisible: false
					},
					{
						key: "reports_captiveGraph",
						text: 'captiveGraph',
						url: "/captiveGraph",
						showPageTitle: false,
						product: "feedback",
						notVisible: false
					},
					{
						key: "reports_detailed_review_request",
						text: 'detailedReviewRequest',
						url: "/detailedReviewRequest",
						// operations: "LEADERBOARD__VIEW",
						showPageTitle: false,
						product: "feedback",
						notVisible: true
					}
				]
			},
			{
				key: "relationships",
				text: 'realationships',
				icon: relationshipsIcon,
				showOnlyIfHasSubscriptionStores: true,
				children: [
					{
						key: "relationships_tasks",
						text: 'tasks',
						url: "/tasks",
						operations: "USERS__VIEW",
						showOnlyIfHasSubscriptionStores: true,
						showPageTitle: false
					}/*,
					{
						key: "relationships_salesDashboard",
						text: 'salesDashboard',
						url: envConfig.frontendPhpUrl + "/salesDashboard.php",
						operations: "USERS__VIEW",
						showOnlyIfHasSubscriptionStores: true
					}*/
				]
			},
			// {
			// 	key: "reservations",
			// 	text: 'reservations',
			// 	icon: reservationsIcon,
			// 	children: [
			// 		{
			// 			key: "reservations_calendar",
			// 			text: 'schedule',
			// 			url: envConfig.frontendPhpUrl + "/reservationAgenda.php",
			// 			operations: "DEPRECATED",
			// 		}/*,
			// 		{
			// 			key: "reservations_report",
			// 			text: 'reservationsReport',
			// 			url: envConfig.frontendPhpUrl + "/reservationDashboard.php",
			// 			operations: "DEPRECATED"
			// 		}*/
			// 	]
			// },
			// {
			// 	key: "config",
			// 	icon: configIcon,
			// 	text: 'config',
			// 	url: "/configurations",
			// 	showPageTitle: false,
			// 	operations: "ALL",
			// 	noChildrenMenu: true,
			// 	children: [
			// 		{
			// 			key: "config_account",
			// 			notVisible: true,
			// 			text: 'accountConfig',
			// 			url: `/settings/administrators/${admin.id}`,
			// 			operations: "STORES__EDIT",
			// 			showPageTitle: false
			// 		},
			// 		{
			// 			key: "config_admin",
			// 			notVisible: true,
			// 			text: 'adminConfig',
			// 			url: envConfig.frontendPhpUrl + "/storeAdmins.php",
			// 			operations: "COMPANIES__EDIT",
			// 			showOnlyIfStoreSelected: true,
			// 			showPageTitle: false
			// 		},
			// 		{
			// 			key: "config_store",
			// 			notVisible: true,
			// 			text: 'storeConfig',
			// 			url: envConfig.frontendPhpUrl + "/storeConfigWizard.php",
			// 			operations: "STORES__EDIT",
			// 			showOnlyIfStoreSelected: true,
			// 			showPageTitle: false
			// 		},
			// 		{
			// 			key: "config_company",
			// 			notVisible: true,
			// 			text: 'companyConfig',
			// 			url: '/companies',
			// 			showPageTitle: false,
			// 		},
			// 		{
			// 			key: "config_promoCode",
			// 			notVisible: true,
			// 			text: 'promoCode',
			// 			url: envConfig.frontendPhpUrl + "/preloadQr.php",
			// 			operations: "COMPANIES__EDIT",
			// 			showPageTitle: false
			// 		},
			// 		{
			// 			key: "config_API",
			// 			notVisible: true,
			// 			text: 'api',
			// 			url: "/api",
			// 			showPageTitle: false,
			// 			operations: "COMPANIES__EDIT",
			// 		},
			// 		{
			// 			key: "config_INTEGRATIONS",
			// 			notVisible: true,
			// 			text: 'integrations',
			// 			url: "/integrations",
			// 			showPageTitle: false,
			// 			operations: "COMPANIES__EDIT",
			// 		},
			// 		{
			// 			key: "config_INTEGRATIONS",
			// 			notVisible: true,
			// 			url: "/integrations/finish/:id",
			// 			showPageTitle: false,
			// 			operations: "COMPANIES__EDIT",
			// 		},
			// 		{
			// 			key: "config_INTEGRATIONS",
			// 			notVisible: true,
			// 			url: "/integrations/finish/:id",
			// 			showPageTitle: false,
			// 			operations: "COMPANIES__EDIT",
			// 		},
			// 		{
			// 			key: "config_INTEGRATIONS",
			// 			notVisible: true,
			// 			url: "/integrations/callback",
			// 			showPageTitle: false,
			// 			operations: "COMPANIES__EDIT",
			// 		},
			// 		{
			// 			key: "config_INTEGRATIONS",
			// 			notVisible: true,
			// 			url: "/integrations/connect/:sourceId",
			// 			showPageTitle: false,
			// 			operations: "COMPANIES__EDIT",
			// 		},
			// 		{
			// 			key: "config_SALESFORCE",
			// 			notVisible: true,
			// 			text: 'salesforce',
			// 			url: "/salesforce",
			// 			showPageTitle: false,
			// 			operations: "COMPANIES__EDIT",
			// 		},
			// 		{
			// 			key: "configurations",
			// 			text: 'configurations',
			// 			url: "/configurations",
			// 			showPageTitle: false,
			// 			operations: "ALL",
			// 		},
			// 		{
			// 			key: "reports_adminInvite",
			// 			text: 'admin-invite',
			// 			url: "/settings/invite",
			// 			showPageTitle: false,
			// 			product: "feedback",
			// 			notVisible: true
			// 		}
			// 	]
			// },
			{
				key: "help",
				text: 'help',
				icon: helpIcon,
				children: [
					{
						key: "help_helpCenter",
						text: 'helpCenter',
						url: "https://help.botbit.io/es/",
						showPageTitle: false,
						target: "_blank",
						norel: "noopener noreferrer"
					},
					{
						key: "help_chat",
						text: 'chat',
						url: "#",
						showPageTitle: false,
						operations: "ALL",
						id: "intercomChat"
					},
					{
						showPageTitle: false,
						key: "error_report",
						text: 'errorReport',
						// operations: "STORE_ANALYTICS__VIEW",
						url: "/error-report"
					},
				]
			},	
		// 	{
		// 		key: "error_report",
		// 		text: 'errorReport',
		// 		icon: metricsIcon,
		// 		url: "/error-report",
		// 		// operations: "STORE_ANALYTICS__VIEW",
		// 		noChildrenMenu: true,
		// 		children: [
		// 			{
		// 				showPageTitle: false,
		// 				key: "error_report",
		// 				text: 'errorReport',
		// 				// operations: "STORE_ANALYTICS__VIEW",
		// 				url: "/error-report"
		// 			},
		// 		]
		// 	},
		]
	}
};
