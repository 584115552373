import React from 'react'
import LogoRay from "./RAY_transparent_logo.png"
import "./logo.scss";

const Logo = ({width, height}) => {
  return (
        <div className="logo-container">
            <img src={LogoRay} alt="Logo" width={width} height={height}/>
        </div>
    )
}

export default Logo