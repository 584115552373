export const translations = {
  categories: {
    label: {
      es: "Categorías",
      en: "Categories",
    },
    subtitle: {
      es: "Actuales",
      en: "Current",
    },
    subtitle2: {
      es: "Primarias",
      en: "Primary",
    },
    subtitle3: {
      es: "Secundarias",
      en: "Secondary",
    },
    status: {
      pending: {
        es: "¡Posibilidad de mejora en tus categorías!",
        en: "Opportunity to improve in your categories!",
      },
      success: {
        es: "¡Excelente! Ya tienes +20% en tu score por las categorias.",
        en: "Great! You have +20% in your score for the categories.",
      },
      moderation: {
        es: "¡Tus cambios se han aplicado y están siendo verificados por Google!",
        en: "Your changes have been applied and are being verified! by Google",
      },
      error: {
        es: "No tener las categorias esta afectando el posicionamiento en Google de esta sucursal.",
        en: "Not having the categories is affecting the positioning of this branch on Google.",
      },
    },
    button: {
      es: "Ver recomendadas",
      en: "See recommended",
    },
    modal: {
      title: {
        es: "Análisis de categorías",
        en: "Categories analysis",
      },
      subtitle: {
        es: "Analizamos tus palabras claves y en base al resultado, te recomendamos reordenarlas o agregar en el caso de que falten.",
        en: "We analyze your keywords and based on the result, we recommend you to reorder them or add them in case they are missing.",
      },
      title2: {
        es: "Actuales",
        en: "Current",
      },
      title3: {
        es: "Recomendadas",
        en: "Recommended",
      },
      title4: {
        es: "Primaria",
        en: "Primary",
      },
      title5: {
        es: "Secundarias",
        en: "Secondary",
      },
      title6: {
        es: "Para mejorar:",
        en: "To improve:",
      },
      title7: {
        es: "Debes dejar al menos 1 categoría secundaria.",
        en: "You must leave at least 1 secondary category.",
      },
      checkbox: {
        es: "sugeridas",
        en: "suggested",
      },
      button1: {
        es: "Aplicar cambios",
        en: "Apply changes",
      },
      button2: {
        es: "Cancelar",
        en: "Cancel",
      },
      verified: {
        es: "Verificado",
        en: "Verified",
      },
      notVerified: {
        es: "No Verificado",
        en: "Not Verified",
      },
      loading: {
        es: "Cargando sugerencias...",
        en: "Loading suggestions...",
      },
      noSecondaryCategories: {
        es: "No hay categorías secundarias",
        en: "No secondary categories",
      },
      categoriesMatch: {
        es: "¡Las categorías actuales coinciden con las recomendadas!",
        en: "Current categories match the recommended ones!",
      },
    },
  },
  tutorial: {
    button: {
      es: "Ver Tutorial",
      en: "Watch Tutorial",
    },
  },
  modalFinish: {
    title: {
      es: "¡Genial! Sumaste puntos al Score por mejorar este local!",
      en: "Great! You added points to the Score for improving this location!",
    },
    subtitle: {
      es: "Importante: Google esta verificando los cambios antes de aplicarlos al perfil de esta sucursal.",
      en: "Important: Google is verifying the changes before applying them to this branch's profile.",
    },
  },
  modalPhotos: {
    photos: {
      additional: {
        title: {
          es: "Subir fotos",
          en: "Upload photos"
        },
        uploadText: {
          es: "Haz clic para subir fotos",
          en: "Click to upload photos"
        }
      },
      profile: {
        title: {
          es: "Subir logo",
          en: "Upload logo"
        },
        uploadText: {
          es: "Haz clic para subir tu logo",
          en: "Click to upload logo" 
        }
      },
      cover: {
        title: {
          es: "Subir portada",
          en: "Upload cover"
        },
        uploadText: {
          es: "Haz clic para subir tu foto de portada",
          en: "Click to upload cover"
        }
      },
      errors: {
        aspectRatio: {
          es: "La imagen debe tener una proporción 16:9.",
          en: "The image must have a 16:9 aspect ratio."
        },
        loadError: {
          es: "No se pudo cargar la imagen para validar la proporción.",
          en: "Could not load the image to validate the aspect ratio."
        },
        uploadError: {
          es: "Error al subir la imagen. Por favor, intenta nuevamente.",
          en: "Error uploading image. Please try again."
        }
      },
      uploading: {
        es: "Subiendo imagen...",
        en: "Uploading image..."
      },
      saving: {
        es: "Guardando cambios...",
        en: "Saving changes..."
      },
      apply: {
        es: "Aplicar cambios",
        en: "Apply changes"
      },
      dragDropText: {
        es: "o arrastra y suelta tus imágenes aquí",
        en: "or drag and drop your images here"
      }
    },
    noLogo: {
      es: "No tienes logo",
      en: "No logo uploaded"
    },
    noCover: {
      es: "No tienes portada",
      en: "No cover uploaded"
    },
    recommendations: {
      label: {
        es: "Recomendado:",
        en: "Recommended:"
      },
      logo: {
        es: "720 x 720 pixels, con un mínimo de 250 x 250 pixels.",
        en: "720 x 720 pixels, with a minimum of 250 x 250 pixels."
      },
      cover: {
        es: "1024 x 576 pixels, con 16:9 aspect ratio.",
        en: "1024 x 576 pixels, with 16:9 aspect ratio."
      }
    }
  },
  noData: {
    es: "No hay datos disponibles",
    en: "No data available",
  },
  saveAndReturn: {
    es: "Terminar y volver",
    en: "Save and return",
  },
  error: {
    es: "Hubo un problema al guardar los cambios. Por favor, intenta nuevamente.",
    en: "There was a problem saving the changes. Please try again.",
  },
  serverError: {
    es: "Error al conectar con el servidor. Por favor, verifica tu conexión e intenta nuevamente.",
    en: "Error connecting to the server. Please check your connection and try again.",
  },
  titleTutorial: {
    es: "Tutorial de soporte sugerido por RAY:",
    en: "Tutorial of support suggested by RAY:",
  },
  mainCategory: {
    es: "Primaria",
    en: "Primary",
  },
  secondaryCategories: {
    es: "Secundarias",
    en: "Secondaries",
  },
  currentCategories: {
    es: "Actuales",
    en: "Current",
  },
  buttons: {
    cancel: {
      es: "Cancelar",
      en: "Cancel",
    },
    apply: {
      es: "Aplicar",
      en: "Apply",
    },
  },
  actionableConfigs: {
    categories: {
      title: {
        es: "Categorías",
        en: "Categories",
      },
      withoutCategories: {
        es: "Sin categorías secundarias",
        en: "Without secondary categories",
      },
      states: {
        success: {
          message: {
            es: "Las categorías están configuradas correctamente",
            en: "Categories are properly configured",
          },
          buttonText: {
            es: "Ver Recomendadas",
            en: "See Recommended",
          },
        },
        error: {
          message: {
            es: "Posibilidad de mejora en tus categorías!",
            en: "Opportunity to improve in your categories!",
          },
          buttonText: {
            es: "Ver Recomendadas",
            en: "See Recommended",
          },
        },
        moderation: {
          message: {
            es: "¡Ya tienes +50% en tu score! Los cambios se han aplicado y están siendo verificados por google.",
            en: "You already have +50% in your score! Changes have been applied and are being verified by Google.",
          },
          buttonText: {
            es: "Ver Recomendadas",
            en: "See Recommended",
          },
        },
      },
    },
    photos: {
      title: {
        es: "Fotos de la sucursal",
        en: "Branch Photos",
      },
      tooltip: {
        title: {
          es: "+60% de vistas",
          en: "+60% of views",
        },
        normalText: {
          es: "Los locales con fotografías propias reciben un",
          en: "Locations with their own photos receive a",
        },
        normalText2: {
          es: ", segun ",
          en: ", according to ",
        },
        boldText: {
          es: " +60% de vistas",
          en: " +60% of views",
        },
        highlightedText: {
          es: "Yext Comparative Listings Study.",
          en: "Yext Comparative Listings Study.",
        },
      },
      states: {
        success: {
          message: {
            es: "¡Excelente! Tienes más de 3 fotos propias publicadas.",
            en: "Great! You have more than 3 of your own photos published.",
          },
          placeholder: {
            es: "Sigue publicando fotos para este local directamente desde RAY.",
            en: "Keep publishing photos for this location directly from RAY.",
          },
          buttonText: {
            es: "+ Subir fotos",
            en: "+ Upload photos",
          },
        },
        error: {
          message: {
            es: "Tienes menos de 3 fotos publicadas.",
            en: "You have less than 3 photos published.",
          },
          placeholder: {
            es: "Recuerda que no se contabilizarán las publicadas por tus clientes.",
            en: "Remember that photos published by your customers will not be counted.",
          },
          buttonText: {
            es: "+ Subir fotos",
            en: "+ Upload photos",
          },
        },
        moderation: {
          message: {
            es: "¡Ya tienes +10% en tu score! Los cambios se han aplicado y están siendo verificados por google.",
            en: "You already have +10% in your score! Changes have been applied and are being verified by Google.",
          },
          buttonText: {
            es: "+ Subir fotos",
            en: "+ Upload photos",
          },
        },
      },
    },
    logo: {
      title: {
        es: "Logo",
        en: "Logo",
      },
      states: {
        success: {
          message: {
            es: "Ya tienes un logo.",
            en: "You already have a logo.",
          },
          placeholder: {
            es: "No tiene placeholder esto despues lo borro",
            en: "No tiene placeholder esto despues lo borro",
          },
          buttonText: {
            es: "+ Subir logo",
            en: "+ Upload logo",
          },
        },
        error: {
          message: {
            es: "Agrega un logo para sumar puntos y optimizar tu perfil!",
            en: "Add a logo to add points and optimize your profile!",
          },
          placeholder: {
            es: "",
            en: "",
          },
          buttonText: {
            es: "+ Subir logo",
            en: "+ Upload logo",
          },
        },
        moderation: {
          message: {
            es: "¡Ya tienes +5% en tu score! Los cambios se han aplicado y están siendo verificados por google.",
            en: "You already have +5% in your score! Changes have been applied and are being verified by Google.",
          },
          buttonText: {
            es: "+ Subir fotos",
            en: "+ Upload photos",
          },
        },
      },
    },
    cover: {
      title: {
        es: "Portada",
        en: "Cover",
      },
      states: {
        success: {
          message: {
            es: "Ya tienes una foto de portada.",
            en: "You already have a cover photo.",
          },
          placeholder: {
            es: "No tiene placeholder esto despues lo borro",
            en: "No tiene placeholder esto despues lo borro",
          },
          buttonText: {
            es: "+ Subir portada",
            en: "+ Upload cover",
          },
        },
        error: {
          message: {
            es: "Agrega una foto de para sumar puntos y optimizar tu perfil!",
            en: "Add a cover photo to add points and optimize your profile!",
          },
          placeholder: {
            es: "",
            en: "",
          },
          buttonText: {
            es: "+ Subir portada",
            en: "+ Upload cover",
          },
        },
        moderation: {
          message: {
            es: "¡Ya tienes +5% en tu score! Los cambios se han aplicado y están siendo verificados por google.",
            en: "You already have +5% in your score! Changes have been applied and are being verified by Google.",
          },
          buttonText: {
            es: "+ Subir fotos",
            en: "+ Upload photos",
          },
        },
      },
    },
    googlePost: {
      title: {
        es: "Google Post",
        en: "Google Post",
      },
      tooltip: {
        title: {
          es: "+45% de vistas",
          en: "+45% of views",
        },
        normalText: {
          es: "Los locales con Google Posts reciben un",
          en: "Locations with Google Posts receive a",
        },
        normalText2: {
          es: ", segun ",
          en: ", according to ",
        },
        boldText: {
          es: " +45% de vistas",
          en: " +45% of views",
        },
        highlightedText: {
          es: "Yext Comparative Listings Study.",
          en: "Yext Comparative Listings Study.",
        },
      },
      states: {
        success: {
          message: {
            es: "¡Excelente! Publicaste contenido en los últimos 15 días.",
            en: "Great! You have posted content in the last 15 days.",
          },
          placeholder: {
            es: "Sigue generando contenido para este local directamente desde RAY.",
            en: "Continue generating content for this location directly from RAY.",
          },
          buttonText: {
            es: "+ Crear post",
            en: "+ Create post",
          },
        },
        error: {
          message: {
            es: "Hace más de 15 días no publicas y la sucursal está perdiendo tráfico!",
            en: "You haven't posted in over 15 days, and the branch is losing traffic!",
          },
          placeholder: {
            es: "Publica un nuevo post para atraer más tráfico.",
            en: "Publish a new post to attract more traffic.",
          },
          buttonText: {
            es: "+ Crear post",
            en: "+ Create post",
          },
        },
        moderation: {
          message: {
            es: "¡Ya tienes +10% en tu score! Los cambios se han aplicado y están siendo verificados por google.",
            en: "You already have +10% in your score! Changes have been applied and are being verified by Google.",
          },
          buttonText: {
            es: "+ Crear post",
            en: "+ Create post",
          },
        },
      },
    },
    phone: {
      title: {
        es: "Teléfono",
        en: "Phone",
      },
      noPhone: {
        es: "Esta sucursal no tiene teléfono, debes cambiarlo en tu perfil",
        en: "This location has no phone number, you must change it in your profile"
      },
      states: {
        success: {
          message: "Tu número de teléfono está verificado",
          buttonText: {
            es: "Editar",
            en: "Edit",
          },
        },
        error: {
          message: "Tu número de teléfono necesita verificación",
          buttonText: {
            es: "Editar",
            en: "Edit",
          },
        },
      },
    },
    website: {
      title: {
        es: "Website",
        en: "Website",
      },
      invalidUrl: {
        es: "Url inválida",
        en: "Invalid url"
      },
      tooltip: {
        title: {
          es: "+45% de vistas",
          en: "+45% of views",
        },
        normalText: {
          es: "Los locales con Website asociada reciben un",
          en: "Locations with a website receive a",
        },
        normalText2: {
          es: ", segun ",
          en: ", according to ",
        },
        boldText: {
          es: " +45% de vistas",
          en: " +45% of views",
        },
        highlightedText: {
          es: "Yext Comparative Listings Study.",
          en: "Yext Comparative Listings Study.",
        },
      },
      states: {
        success: {
          message: {
            es: "¡Excelente! Ya tienes +15% en tu score por el website.",
            en: "Great! You already have +15% in your score for the website.",
          },
          placeholder: {
            es: "Mantén tu sitio actualizado.",
            en: "Keep your site updated.",
          },
          buttonText: {
            es: "Editar",
            en: "Edit",
          },
        },
        error: {
          message: {
            es: "Sitio web inválido, por favor asegúrate de que la URL funcione y no dirija a una red social.",
            en: "Invalid website, please make sure the URL is correct and is not your social media feed.",
          },
          placeholder: {
            es: "Agrega un enlace a tu sitio.",
            en: "Add a link to your site.",
          },
          buttonText: {
            es: "Editar",
            en: "Edit",
          },
        },
        moderation: {
          message: {
            es: "¡Ya tienes +15% en tu score! Los cambios se han aplicado y están siendo verificados por Google!",
            en: "You already have +15% in your score! Changes have been applied and are being verified by Google!",
          },
          buttonText: {
            es: "Editar",
            en: "Edit",
          },
        },
      },
    },
    description: {
      title: {
        es: "Descripción",
        en: "Description",
      },
      tooltip: {
        title: {
          es: " +45% de vistas",
          en: " +45% of views",
        },
        normalText: {
          es: "Los locales con una descripción completa reciben un",
          en: "Locations with a complete description receive a",
        },
        normalText2: {
          es: ", segun ",
          en: ", according to ",
        },
        boldText: {
          es: " +45% de vistas",
          en: " +45% of views",
        },
        highlightedText: {
          es: "Yext Comparative Listings Study.",
          en: "Yext Comparative Listings Study.",
        },
      },
      states: {
        success: {
          message: {
            es: "¡Excelente! Ya tienes +10% en tu score por tu descripción.",
            en: "Great! You already have +10% in your score for your description.",
          },
          placeholder: {
            es: "Edita la descripción si lo consideras necesario.",
            en: "Edit the description if you consider it necessary.",
          },
          buttonText: {
            es: "Editar",
            en: "Edit",
          },
        },
        error: {
          message: {
            es: "Falta una descripción detallada, mínimo 150 caracteres.",
            en: "A detailed description is missing, minimum 150 characters.",
          },
          placeholder: {
            es: "Agrega una descripción atractiva para tu negocio.",
            en: "Add an attractive description for your business.",
          },
          buttonText: {
            es: "Editar",
            en: "Edit",
          },
        },
        moderation: {
          message: {
            es: "¡Ya tienes +10% en tu score! Los cambios se han aplicado y están siendo verificados por google.",
            en: "You already have +10% in your score! Changes have been applied and are being verified by Google.",
          },
          buttonText: {
            es: "Editar",
            en: "Edit",
          },
        },
      },
    },
    address: {
      title: {
        es: "Dirección",
        en: "Address",
      },
    },
  },
  score: {
    howItWorks: {
      es: "¿Cómo funciona el Score?",
      en: "How does the Score work?",
    },
  },
  analysis: {
    title: {
      es: "Analizamos tus locales",
      en: "We analyze your locations",
    },
    description: {
      es: "En RAY, realizamos un análisis exhaustivo de tu local de Google para obtener información sobre su estado.",
      en: "In RAY, we perform a comprehensive analysis of your Google location to obtain information about its status.",
    },
  },
  scoring: {
    title: {
      es: "Puntuamos tus locales",
      en: "We score your locations",
    },
    description: {
      es: `Una vez completado el análisis, RAY le asigna una "puntuación" a cada uno de tus locales en base a las opotunidades de mejora que fueron identificadas durante dicho análisis.`,
      en: `Once the analysis is complete, RAY assigns a "score" to each of your locations based on the improvement opportunities identified during the analysis.`,
    },
  },
  classification: {
    title: {
      es: "Clasificamos tus locales",
      en: "We classify your locations",
    },
    description: {
      es: "Después de puntuar tus locales, RAY los clasificó visualmente mediante un sistema de colores para una rápida identificación de su estado.",
      en: "After scoring your locations, RAY classifies them visually using a color system for quick status identification.",
    },
  },
  buttonBack: {
    es: "Volver a la lista",
    en: "Back to the list",
  },
  alt: {
    companyLogo: {
      es: "Logo de la empresa",
      en: "Company logo"
    },
    companyCover: {
      es: "Portada de la empresa", 
      en: "Company cover"
    },
    icon: {
      es: "Icono",
      en: "Icon"
    }
  },
};
