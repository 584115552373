import React from 'react'
import { envConfig } from '../../config/config';
import { Image } from "botbit-ui-components"
import SpinnerV2 from '../../commons/components/utils/spinner/SpinnerV2';
export const NewLoader = ({ className = "", white }) => {

  return (
    <div className={`w-100 d-flex justify-content-center align-items-center ${className}`}>
      <SpinnerV2 />
    </div>
  )
}